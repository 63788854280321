import { Col, InputNumber, InputNumberProps, Row, Slider, Switch, Tag, Typography } from 'antd';
import { FlexRow, Paper } from '../../../components/common/common';
import { ComparisonIntensitiesData, ComparisonSignatureData, GenericModel, GenericModelV2 } from '../../../components/analysis/definitions';
import { isPassFailModelData } from '../../../utils/helpers/model';

type GlobalParametersComponentProps = {
  showGlobalParameters: boolean;
  signatureWindowValue: number;
  setSignatureWindowValue: React.Dispatch<React.SetStateAction<number>>;
  thresholdOverride: number;
  setThresholdOverride: React.Dispatch<React.SetStateAction<number>>;
  currentModel: GenericModelV2 | null;
  setCurrentModel: React.Dispatch<React.SetStateAction<GenericModelV2 | null>>;
  noizeLevelRef: React.MutableRefObject<number>;
  humidityCompensationEnabled: boolean;
  sethumidityCompensationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalParametersIdentificationComponent: React.FC<GlobalParametersComponentProps> = ({
  showGlobalParameters,
  signatureWindowValue,
  setSignatureWindowValue,
  thresholdOverride,
  setThresholdOverride,
  currentModel,
  setCurrentModel,
  noizeLevelRef,
  humidityCompensationEnabled,
  sethumidityCompensationEnabled,
}) => {
  const onSignatureWindowSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setSignatureWindowValue(newValue as number);
  };

  const onComparisonThresholdChange: InputNumberProps['onChange'] = (newValue) => {
    if (newValue !== null) {
      setThresholdOverride(newValue as number);
      if (currentModel !== null && isPassFailModelData(currentModel.data)) {
        setCurrentModel({
          ...currentModel,
          data: {
            ...currentModel.data,
            comparisonThreshold: newValue as number,
          },
        });
      }
    }
  };

  return (
    <>
      {showGlobalParameters && (
        <Paper style={{ width: '100%', marginTop: 50, marginBottom: 30 }}>
          <Typography.Title level={5} style={{ margin: 'auto', textAlign: 'center', width: '100%', marginBottom: 30 }}>
            Global parameters
          </Typography.Title>

          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Current model</Col>
            <Col span={12}>{currentModel?.metadata.ID}</Col>
            <Col span={4}></Col>
          </Row>
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Analyte duration</Col>
            <Col span={12}>
              <Slider min={1} max={20} onChange={onSignatureWindowSliderChange} value={typeof signatureWindowValue === 'number' ? signatureWindowValue : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} value={signatureWindowValue} onChange={onSignatureWindowSliderChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: 15, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Comparison Threshold</Col>
            <Col span={12}>
              <InputNumber min={0} value={thresholdOverride} onChange={onComparisonThresholdChange} />
            </Col>
            <Col span={4}></Col>
          </Row>
          {currentModel?.metadata.debug && (
            <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
              <Col span={6}>Noise level</Col>
              <Col span={12} style={{ fontWeight: 'bold' }}>
                {noizeLevelRef.current.toFixed(2)}
              </Col>
              <Col span={4}></Col>
            </Row>
          )}
          <Row style={{ marginTop: 20, width: '100%' }} align="middle" justify="space-between">
            <Col span={6}>Humidity correction enabled</Col>
            <Col span={12}>
              <Switch onChange={sethumidityCompensationEnabled} defaultChecked={humidityCompensationEnabled} />
            </Col>
            <Col span={4}></Col>
          </Row>
        </Paper>
      )}
    </>
  );
};

export default GlobalParametersIdentificationComponent;
