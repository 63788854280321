export const OBJECTIVE_COMPARISON_SIMILAR = 'SIMILAR';
export const OBJECTIVE_COMPARISON_DIFFERENT = 'DIFFERENT';
export const INTERPRETED_COMPARISON_PASS = 'PASS';
export const INTERPRETED_COMPARISON_FAIL = 'FAIL';

/*
N - number of records
M - number of spots
*/

export type signal = number[][]; // NxM matrix
export type signature = number[]; // 1xM matrix

export enum AggregationMethod {
  Mean = 'Mean',
  Median = 'Median',
}

export interface Envelope {
  avg: number;
  min: number;
  max: number;
}

export interface OdorDetectionInfos {
  isOdorPresent: boolean;
  noizeLevel: number;
  thresholdLevel: number;
  maxIntensity: number;
  startTimestamp: number;
  stopTimestamp: number;
  lastRecognitionTimestamp: number;
}
/*
Full ellipse definition. To be used in the App
*/
export interface ellipse {
  group: string;
  cx: number; // Center, x
  cy: number; // Center, y
  a: number; // Semi-major axis
  b: number; // Semi-minor axis
  theta: number; // Counter-clockwise angle of rotation in radians (between the x axis and the semi-major axis)
}

/*
Simplified version of ellipse. Already calculated, translated and rotated focal points (x,y)
To be transmitted to the watch
*/
export interface simplifiedEllipse {
  group: string;
  p: number; // Perimeter: 2*a
  focusAx: number; // Coordinates of the right focus of an ellipse, x
  focusAy: number; // Coordinates of the right focus of an ellipse, y
  focusBx: number; // Coordinates of the left focus of an ellipse, x
  focusBy: number; // Coordinates of the left focus of an ellipse, y
}

// Define interfaces for the YAML structure
export interface Rule {
  condition: string;
  result: string;
}

export interface Threshold {
  rawMziDetectionThreshold: number;
  distanceToWaterCalibrantThreshold: number;
  humidityCorrectedMziExploitableThreshold: number;
}

export interface WaterCalibrant {
  id: string;
  timestamp: number;
  waterAffinities: number[];
  spotgrid: number[];
  rawSignature: number[];
}

export interface ClassificationRules {
  rules: Rule[];
  thresholds?: Threshold;
  waterCalibrant?: WaterCalibrant;
  default: string;
}

// Define the types for barycenters
export interface Barycenters {
  [key: string]: [number, number];
}

// define enum
export enum ModelCategory {
  Barycenters = 'Barycenters',
  ComparisonSignatures = 'ComparisonSignatures',
  ComparisonIntensities = 'ComparisonIntensities',
  ChemicalPrediction = 'ChemicalPrediction',
  RulesBased = 'RulesBased',
  Ellipses = 'Ellipses',
}

export enum DisplayModelType {
  Ellipses = 'Ellipses',
  ChemicalPrediction = 'ChemicalPrediction',
  PassFail = 'PassFail',
  OneAmongN = 'OneAmongN',
}

// define the types for custom min max normalisation
export interface normalisationRules {
  minSpot: number;
  maxSpot: number;
}

export type Model = GenericModel | GenericModelV2;

// For Generic Models
// Define the main data structure
// TODO soon be deprecated
export interface GenericModel {
  metadata: Metadata;
  projection_axis?: number[][];
  barycenters?: Barycenters;
  referencesSignatures?: number[][];
  referenceIntensities?: number[];
  comparisonThreshold?: number;
  analyteDuration?: number;
  chemicalRules?: ClassificationRules;
  normalisationForChemicalClassification?: normalisationRules;
  barycentersAsSignatures?: Record<string, number[]>[];
  classToStimulationDictionnary?: ClassToStimulationDictionary;
}

export type ModelData = ComparisonSignatureData | ComparisonIntensitiesData | BarycentersData | EllipsesData | ChemicalPredictionData | RulesBasedData;

export interface GenericModelV2 {
  metadata: Metadata;
  data: ModelData;
}

// Define the types for metadata
export interface Metadata {
  created_at: string;
  spotfile: number[];
  ID: string;
  type?: ModelCategory;
  debug?: boolean;
}

export interface ComparisonSignatureData {
  type: ModelCategory.ComparisonSignatures;

  // parameters
  analyteDuration?: number;
  comparisonThreshold: number;

  // computed data
  referencesSignatures: number[][];
}

export interface ComparisonIntensitiesData {
  type: ModelCategory.ComparisonIntensities;

  // parameters
  analyteDuration?: number;
  comparisonThreshold: number;

  // computed data
  referencesIntensities: number[];
}

export interface BarycentersData {
  type: ModelCategory.Barycenters;

  // parameters
  analyteDuration?: number;

  // computed data
  barycenters: Barycenters;
  projectionAxis: number[][];
}

export interface EllipsesData {
  type: ModelCategory.Ellipses;

  // parameters
  analyteDuration?: number;
  sigmaX: number;
  sigmaY: number;

  // computed data
  groupedScaledEllipses: Record<string, number[]>;
  pcaEigenvectors: number[][];
  cmap: Record<string, [number, number, number]>;
}

export interface ChemicalPredictionData {
  type: ModelCategory.ChemicalPrediction;

  // params
  analyteDuration?: number;
  chemicalRules: ClassificationRules;
  normalisationForChemicalClassification: normalisationRules;
  classToStimulationDictionnary: ClassToStimulationDictionary;

  // computed
  barycentersAsSignatures?: Record<string, number[]>[];
  barycenters: Barycenters;
  projection_axis?: number[][];
}

export interface RulesBasedData {
  type: ModelCategory.RulesBased;

  // params
  analyteDuration?: number;
  rules: ClassificationRules;
}

export interface ClassToStimulationDictionary {
  amine: Stimulation;
  humid_air: Stimulation;
  ketone: Stimulation;
  alcohol: Stimulation;
  acid: Stimulation;
  terpene: Stimulation;
  phenolic: Stimulation;
}

export interface Stimulation {
  frequency: number;
  duration: number;
}
