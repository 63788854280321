import { BugOutlined, BugTwoTone } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type DebugInfoProps = {
  showDebugInfo: boolean;
  setShowDebugInfo: React.Dispatch<React.SetStateAction<boolean>>;
};

const DebugInfo: React.FC<DebugInfoProps> = ({ showDebugInfo, setShowDebugInfo }) => {
  return (
    <Tooltip overlay="Toggle debug info">
      <Button
        type="text"
        size="large"
        icon={showDebugInfo ? <BugTwoTone /> : <BugOutlined />}
        onClick={() => {
          setShowDebugInfo(!showDebugInfo);
        }}
      />
    </Tooltip>
  );
};

export default DebugInfo;
