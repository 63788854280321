import { useEffect, useRef, useState } from 'react';
import { message as antdMessage } from 'antd';
import BaselineRecording from './OdorIdentifiacationSteps/BaselineRecording';
import AnalyteRecording from './OdorIdentifiacationSteps/AnalyteRecording';
import OdorAnalysis from './OdorIdentifiacationSteps/OdorAnalysis';
import OdorDisplay from './OdorIdentifiacationSteps/OdorDisplay';
import GenericDisplay from './OdorIdentifiacationSteps/GenericDisplay';
import SensorCleaning from './OdorIdentifiacationSteps/SensorCleaning';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE } from '../../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadSpotsGrid1D, loadModel } from '../../services/cache/localStorage';
import { DEFAULT_ANALYSIS_COMPARISON_THRESHOLD, DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_PLOT_DECIMATED_FPS } from '../../utils/constants/constants';
import { mean, transpose } from '../../components/analysis/utils';
import {
  aggregateSignature,
  normalizeL2,
  sortSignature,
  parseBiosensorsSignalMessagePayload,
  updateKineticSeries,
  shouldCompute,
  updateQuestioningState,
  minmaxNormaliseWithGenericModelSpotDefinition,
  computeEnvelope,
  correctHumidity,
} from '../../components/analysis/compute';
import { processMziData } from '../../components/analysis/mzi';
import { AggregationMethod, GenericModelV2, ModelCategory } from '../../components/analysis/definitions';
import { QuestionningResult, SignatureWithSpotgrid } from '../../types/types';
import { classifySignatureChemical, classifySignatureWithModel } from '../../components/analysis/classifier';
import { Row } from 'antd';
import { Paper } from '../../components/common/common';
import { useMessageContext } from '../../state/context/MessageContext';
import BubbleChart, { SecondData } from '../../components/widgets/ChemicalFamilyIdentification/BubbleChart';
import GlobalParameters from '../../components/widgets/Parameters/GlobalParameters';
import ResetToZero from '../../components/widgets/Parameters/ResetToZero';
import GlobalParametersIdentificationComponent from './OdorIdentificationComponents/GlobalParametersComponent';
import { instanceOfGenericModelV2Type } from '../../utils/helpers/model';

type CsmOdorIdentificationProps = {};
enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  AnalyteRecording = 'AnalyteRecording',
  GenericDisplay = 'GenericDisplay',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}

export const CsmOdorIdentification: React.FC<CsmOdorIdentificationProps> = () => {
  const { csmMessages, csmFwVersion, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);

  // for acquisition
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));
  const lastDecimationTickRef = useRef<number>(0);
  const rawMZISeriesRef = useRef<number[][]>([]);
  const isMZIcomputingrequested = useRef<boolean>(false);

  // Corrections
  const firstMZIsRef = useRef<number[] | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(false);
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);

  // signature and prediction computation
  const signalMZI = useRef<number[]>([]);
  const analyteMZISeriesRef = useRef<number[][]>([]);
  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);
  const [currentModel, setCurrentModel] = useState<GenericModelV2 | null>(null);
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);
  const [currentProba, setCurrentProba] = useState<SecondData[]>([]); // chemical bubble display

  // state update and display
  const signalEnvelopeAvgRef = useRef<number>(0);
  const questioningState = useRef<QuestioningState>(QuestioningState.BaselineRecording);
  const averageMZISeriesRef = useRef<number[]>([]);
  const decimatedMZISeriesCorrected = useRef<number[][]>([]);
  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const humidityDisplayed = useRef<number>(0);
  const temperatureDisplayed = useRef<number>(0);

  // various parameters
  const [showGlobalParameters, setShowGlobalParameters] = useState<boolean>(false);
  const [signatureWindowValue, setSignatureWindowValue] = useState(DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
  const [thresholdOverride, setThresholdOverride] = useState(DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // reset MZI for display centered around 0
  const onClickReset = () => {
    questioningState.current = QuestioningState.BaselineRecording;
    setQuestionningSignature([]);

    rawMZISeriesRef.current = [];
    firstMZIsRef.current = null;

    noizeLevelRef.current = 0;
    isOdorPresentRef.current = false;
    odorPresenceThresholdLevelRef.current = 0;
    maxOdorPresentValue.current = 0;
    signalEnvelopeAvgRef.current = 0;

    analyteMZISeriesRef.current = [];

    setQuestionningResult(null);
  };

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
  }, []);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    // console.log("sense page: acquiring mutex..")
    if (!currentSpotsgrid1d) {
      console.log('odor identification page: spotsgrid is empty');
      return;
    }

    // implement me : unfoldBiosensorEvent

    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        let nFramesProcessed = 0;

        csmMessages.forEach((message) => {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            return;
          } else {
            nFramesProcessed++;
            consumeCSMMessage(message.id);

            // ------ Retrieve raw data from the device ------
            // -----------------------------------------------
            let mzis = parseBiosensorsSignalMessagePayload(message, csmFwVersion);
            if (!mzis) return;

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...mzis];
            }
            rawMZISeriesRef.current.push(mzis);

            // set Baseline delta H for future use
            if (humidityBaselineRef.current === null) {
              console.log('Changing humidity baseline reference as ', hihValues.humidity);
              humidityBaselineRef.current = hihValues.humidity;
            }

            // ------ Process and get last decimated MZI frame ------
            // ------------------------------------------------------
            isMZIcomputingrequested.current = shouldCompute(message.ts, lastDecimationTickRef); // shouldCompute is in fact decimation

            // decimation to prevent frequent rendereing
            if (isMZIcomputingrequested.current) {
              // Humidity correction not applied here because we want to extract the firstMZI-substracted MZI for the signature computation
              let MziProcessingResult = processMziData(rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, false, undefined, null, hihValues);
              rawMZISeriesRef.current = [];

              if (MziProcessingResult === undefined) return;
              let [, , decimatedMZI] = MziProcessingResult;

              // Note : you can add any further processing on decimated MZI here to compute some correctedMZI (for gauge display, or state updates) !
              // Just keep in mind the input for questioning signature computation is NOT the correctedMZI, but always the decimatedMZI
              // Indeed, the questioning signature has its own processing pipeline and we don't want to apply multiple or partial corrections (when using correctedMZI)
              let correctedMZI = [...decimatedMZI];
              if (humidityCompensationEnabled && humidityCalibrant !== undefined && humidityBaselineRef.current !== null && currentSpotsgrid1d !== null) {
                correctedMZI = correctHumidity(decimatedMZI, hihValues.humidity - humidityBaselineRef.current, currentSpotsgrid1d, humidityCalibrant);
              }

              let [averageMZI, ,] = computeEnvelope(correctedMZI, currentSpotsgrid1d);
              updateKineticSeries(averageMZI, correctedMZI, averageMZISeriesRef, decimatedMZISeriesCorrected);

              // console.log('averageMZI...', averageMZI, ' humidity correction is', humidityCompensationEnabled, 'lastDecimationTickRef.current is', lastDecimationTickRef.current)

              // Update displayed values
              signalEnvelopeAvgRef.current = averageMZI; // for display in the component
              signalMZI.current = decimatedMZI;
              humidityDisplayed.current = hihValues.humidity;
              temperatureDisplayed.current = hihValues.temperature;

              // * here nightmare starts ..
              // update algo state
              updateQuestioningState(questioningState, averageMZI, averageMZISeriesRef.current, decimatedMZISeriesCorrected.current, isOdorPresentRef, maxOdorPresentValue, odorPresenceThresholdLevelRef, noizeLevelRef);
              console.log('algo state is', questioningState.current);
              // * that was not so hard
            }
          }
        });
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    let _model = loadModel();
    if (_model === null) return;
    if (!instanceOfGenericModelV2Type(_model)) {
      antdMessage.error(`Old model detected, please migrate it to the new structure !`);
      return;
    }
    console.log(' Selected Model is :', _model);
    setSignatureWindowValue(_model?.data.analyteDuration || DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
    if (_model?.data.type === ModelCategory.ComparisonSignatures || _model?.data.type === ModelCategory.ComparisonIntensities) {
      setThresholdOverride(_model?.data.comparisonThreshold || DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
    }
    setCurrentModel(_model);
  }, []);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    if (questionningSignature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      return;
    }
    if (questionningSpotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }

    // classify according to the model type

    if (currentModel?.data.type === ModelCategory.ChemicalPrediction) {
      // console.log('getting chemical prediction questionning spotgrid is', questionningSpotsgrid1d);
      const prediction = classifySignatureChemical(currentModel, questionningSignature, questionningSpotsgrid1d)[0];
      console.log('prediction is', prediction);

      // set in structure to display bubble later
      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: Object.entries(currentModel.data.barycenters || {}).map(([key, value]) => ({
            peptide: key,
            ratio: key === prediction ? 35 : 7, // hardcode found chemical family : size 3 other wise 1.
          })),
        },
      ];
      console.log('packagedData', packagedData);
      setCurrentProba(packagedData);
    } else {
      console.log('other type of model - not chemical');
    }
    let [label, point] = classifySignatureWithModel(currentModel, questionningSignature, questionningSpotsgrid1d);

    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };
    setQuestionningResult(_questionningResult);
    questioningState.current = QuestioningState.OdorDisplay;
  }, [questionningSignature, currentModel]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorDisplay) return;
    setTimeout(() => {
      questioningState.current = QuestioningState.SensorCleaning;
    }, 10000);
  }, [questioningState, questionningSignature]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.SensorCleaning) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) {
      // purge analyteMZISeriesRef
      analyteMZISeriesRef.current = [];
      questioningState.current = QuestioningState.BaselineRecording;
    }
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) {
      // purge analyteMZISeriesRef
      analyteMZISeriesRef.current = [];
      questioningState.current = QuestioningState.BaselineRecording;
    } else {
      // compute signature and return
      // console.log('compute and return');

      constructSignature();
    }
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.AnalyteRecording) return;

    // capture frames once analyte is started during a specified signatureWindowValue amount of time
    if (analyteMZISeriesRef.current.length < DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue) {
      analyteMZISeriesRef.current.push(signalMZI.current);
    }
    console.log('analyte buffer is ', analyteMZISeriesRef.current);
    // buffer is filled and kept at initial value, not sliding value. We could implement a toggle if the user prefer the initial signature or the sliding one
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  const constructSignature = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue;
    }
    let sectionMZIs = analyteMZISeriesRef.current;
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d, AggregationMethod.Median);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    console.log('sortedFinaleSignature', finalSignature);
    console.log('sortedFinalSpotsgrid1d', sortedFinalSpotsgrid1d);
    // disable normallisation when comparing intensities
    if (currentModel?.metadata?.type === ModelCategory.ComparisonIntensities) {
      setQuestionningSignature(sortedFinaleSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    } else {
      let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);
      setQuestionningSignature(normalizedSortedAggregatedSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    }
    console.log(currentModel);
    if (currentModel?.metadata.type === ModelCategory.ChemicalPrediction) {
      // console.log('chemical model detected... ');
      // console.log('raw siganture', finalSignature);
      console.log('min max signature', minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSignature(minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSpotsgrid1d(finalSpotsgrid1d);
    }
  };

  return (
    <>
      {/* parameters */}
      <Row justify="end">
        <GlobalParameters showGlobalParameters={showGlobalParameters} setShowGlobalParameters={setShowGlobalParameters} />
        <ResetToZero firstMZIsRef={firstMZIsRef} onClickReset={onClickReset} />
      </Row>

      {questioningState.current === QuestioningState.BaselineRecording && (
        <BaselineRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.AnalyteRecording && (
        <AnalyteRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.OdorAnalysis && <OdorAnalysis />}
      {questioningState.current === QuestioningState.GenericDisplay && <GenericDisplay result={questionningResult} />}
      {questioningState.current === QuestioningState.OdorDisplay && currentModel?.metadata.type === ModelCategory.ChemicalPrediction && (
        <Paper style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <BubbleChart data={currentProba} currentChemicalFamily={'toto'} />
        </Paper>
      )}
      {questioningState.current === QuestioningState.OdorDisplay && currentModel?.metadata.type !== ModelCategory.ChemicalPrediction && <OdorDisplay result={questionningResult} />}

      {questioningState.current === QuestioningState.SensorCleaning && <SensorCleaning MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />}

      <GlobalParametersIdentificationComponent
        showGlobalParameters={showGlobalParameters}
        signatureWindowValue={signatureWindowValue}
        setSignatureWindowValue={setSignatureWindowValue}
        thresholdOverride={thresholdOverride}
        setThresholdOverride={setThresholdOverride}
        currentModel={currentModel}
        setCurrentModel={setCurrentModel}
        noizeLevelRef={noizeLevelRef}
        humidityCompensationEnabled={humidityCompensationEnabled}
        sethumidityCompensationEnabled={sethumidityCompensationEnabled}
      />
    </>
  );
};
