import { Empty } from 'antd';
import { FlexRow } from '../../common/common';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

type SpotfileNotFoundProps = {};

const SpotfileNotFound: React.FC<SpotfileNotFoundProps> = () => {
  return (
    <FlexRow style={{ width: '100%', justifyContent: 'center' }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <>
            Spotfile <b>not found</b>. Please go to{' '}
            <b>
              <Link to="/device/ble#spotfile">
                spotfile management <LinkOutlined />
              </Link>
            </b>{' '}
            to set it up.
          </>
        }
      />
    </FlexRow>
  );
};

export default SpotfileNotFound;
