import { ApiParitionDataType, ApiResponseType, MultiplePartitionsCheck } from '../../types/types';
import { getPartition, PartitionValue, putPartition, RecordValue } from '../cache/idb';
import { callAPIEndpoint } from './api';
import { parse as uuidParse, stringify as uuidStringify } from 'uuid';

const REQUEST_BODY_BYTE_SIZE_LIMIT = 1000000; // 1 MB max payload size
const NB_BYTES_PARTITION_LENGTH_ENCODING = 2;

export interface ApiAllPartitionKeys {
  sensorgramPartitionKeys: string[];
  humidityPartitionKeys: string[] | null;
  temperaturePartitionKeys: string[] | null;
}

export const encodeApiPartition = (partition: PartitionValue, type: ApiParitionDataType): Uint8Array => {
  // encode partition as a byte array
  // key (uuid): 16 bytes
  // type: 1 byte (1 = sensogram, 2 = humidity, 3 = temperature)
  // absoluteTimestamp (unix timestamp): 8 bytes
  // nFrames (uint32): 2 bytes
  // nDims (uint32): 2 bytes
  // relativeTimestamps (array of uint16): 2 * nFrames bytes
  // sensorgram : series (array of fixed12 encoded as uint16): 2 * nFrames * nDims bytes
  // or
  // sensors : series (array of float32): 4 * nFrames * nDims bytes

  // all numbers are big endian
  // init for sensorgram partitions
  let totalLength = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames + 2 * partition.nFrames * partition.nDims;

  // update if sensors that are float32 encoded
  if (type !== ApiParitionDataType.Sensogram) {
    totalLength = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames + 4 * partition.nFrames * partition.nDims;
  }

  const arrayBuffer = new ArrayBuffer(totalLength); // underlying array buffer
  const dataView = new DataView(arrayBuffer); // view of the (shared) array buffer

  // key
  const keyBytes = new Uint8Array(uuidParse(partition.key).buffer);
  for (let i = 0; i < 16; i++) {
    dataView.setUint8(i, keyBytes[i]);
  }

  // type
  dataView.setUint8(16, +type);

  //  absolute timestamp
  dataView.setBigUint64(16 + 1, BigInt(partition.absoluteTimestamp));
  // console.log('encoding partition: absolute timestamp', partition.absoluteTimestamp, dataView.getBigUint64(17))

  // nFrames
  dataView.setUint16(16 + 1 + 8, partition.nFrames);

  // nDims
  dataView.setUint16(16 + 1 + 8 + 2, partition.nDims);

  // relative timestamps
  let relativeTimestampsOffset = 16 + 1 + 8 + 2 + 2;
  for (let i = 0; i < partition.relativeTimestamps.length; i++) {
    dataView.setUint8(relativeTimestampsOffset + i, partition.relativeTimestamps[i]);
  }

  // series
  let seriesOffset = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames;
  for (let i = 0; i < partition.series.length; i++) {
    dataView.setUint8(seriesOffset + i, partition.series[i]);
  }

  // console.log('encoded partition', arrayBuffer);

  return new Uint8Array(arrayBuffer);
};

export const decodeApiPartition = (arrayBuffer: ArrayBuffer): PartitionValue => {
  let dataView = new DataView(arrayBuffer);

  // key
  let key = uuidStringify(new Uint8Array(arrayBuffer.slice(0, 16)));

  // type
  let type = dataView.getUint8(16);

  // absolute timestamp
  let absoluteTimestamp = Number(dataView.getBigUint64(16 + 1));

  // nFrames
  let nFrames = dataView.getUint16(16 + 1 + 8);

  // nDims
  let nDims = dataView.getUint16(16 + 1 + 8 + 2);

  // relative timestamps
  let relativeTimestampsOffset = 16 + 1 + 8 + 2 + 2;
  let relativeTimestamps = new Uint8Array(arrayBuffer.slice(relativeTimestampsOffset, relativeTimestampsOffset + 2 * nFrames));

  // series
  let seriesOffset = 16 + 1 + 8 + 2 + 2 + 2 * nFrames;
  let seriesTypeSizeCoeff = 1;

  // if type is not a sensorgram then values are encoded on 4 bytes (float32), not 2 (fixed12 as uint16)
  if (type !== 1) {
    seriesTypeSizeCoeff = 2;
  }
  let series = new Uint8Array(arrayBuffer.slice(seriesOffset, seriesOffset + 2 * nFrames * nDims * seriesTypeSizeCoeff));

  let partition: PartitionValue = {
    key: key,
    absoluteTimestamp: absoluteTimestamp,
    nFrames: nFrames,
    nDims: nDims,
    relativeTimestamps: relativeTimestamps,
    series: series,
  };
  return partition;
};

const stackEncodedPartitions = (partitionsToStack: Uint8Array[]): Uint8Array => {
  let totalLength = partitionsToStack.reduce((total, array) => total + array.byteLength, 0);
  totalLength += NB_BYTES_PARTITION_LENGTH_ENCODING * partitionsToStack.length;

  let stackedPartitions = new Uint8Array(totalLength);
  const dataView = new DataView(stackedPartitions.buffer); // view of the (shared) array buffer

  let currentPosition = 0;
  for (let partition of partitionsToStack) {
    dataView.setInt16(currentPosition, partition.byteLength);

    stackedPartitions.set(partition, currentPosition + NB_BYTES_PARTITION_LENGTH_ENCODING);

    currentPosition += partition.byteLength + NB_BYTES_PARTITION_LENGTH_ENCODING;
  }

  return stackedPartitions;
};

const unstackEncodedPartitions = (partitionsToUnstack: ArrayBuffer): ArrayBuffer[] => {
  var partitions: ArrayBuffer[] = [];
  var dataview = new DataView(partitionsToUnstack);

  for (let i = 0; i < partitionsToUnstack.byteLength; ) {
    let partitionLength = dataview.getInt16(i);
    partitions.push(partitionsToUnstack.slice(i + NB_BYTES_PARTITION_LENGTH_ENCODING, i + NB_BYTES_PARTITION_LENGTH_ENCODING + partitionLength));
    i += NB_BYTES_PARTITION_LENGTH_ENCODING + partitionLength;
  }
  return partitions;
};

const apiPostPartition = async (partition: PartitionValue, recordID: string, type: ApiParitionDataType) => {
  console.log('posting partition', partition);
  let encodedPartition = encodeApiPartition(partition, type);
  void (await callAPIEndpoint(`/partition?record_id=${recordID}`, 'POST', encodedPartition));
};

const apiPostEncodedStackedPartitions = async (stackedPartitions: Uint8Array, recordID: string) => {
  console.log('posting partitions');
  void (await callAPIEndpoint(`/partitions/write?record_id=${recordID}`, 'POST', stackedPartitions));
};

const apiCheckPartitionExists = async (partitionKey: string) => {
  let partitionKeyExists: {
    Exists: boolean;
  } = await callAPIEndpoint(`/partition/exists?partition_id=${partitionKey}`, 'GET');
  return partitionKeyExists.Exists;
};

const apiCheckMultiplePartitionsExist = async (partitionIds: string[]) => {
  let resp: MultiplePartitionsCheck = await callAPIEndpoint(`/partitions/exist`, 'POST', JSON.stringify({ IDs: partitionIds }));
  return resp;
};

export const apiPostRecordPartitions = async (record: RecordValue) => {
  if (!record.sensogramPartitionKeys) {
    return;
  }
  let tasks = [];

  // push sensorgrams partitions
  let sensorgramPartitionsChecks = await apiCheckMultiplePartitionsExist(record.sensogramPartitionKeys);
  for (let partitionKey of sensorgramPartitionsChecks.Missing) {
    tasks.push(async () => {
      try {
        let partition = await getPartition(partitionKey);
        await apiPostPartition(partition, record.key, ApiParitionDataType.Sensogram);
      } catch (e: any) {
        throw new Error(`error posting sensogram partition ${partitionKey}: ${e.message}`);
      }
    });
  }

  // push humidity partitions
  if (record.humidityPartitionKeys !== undefined) {
    let humidityPartitionsChecks = await apiCheckMultiplePartitionsExist(record.humidityPartitionKeys);
    for (let partitionKey of humidityPartitionsChecks.Missing) {
      // console.log("posting humidity partition", partitionKey)
      tasks.push(async () => {
        try {
          let partition = await getPartition(partitionKey);
          await apiPostPartition(partition, record.key, ApiParitionDataType.Humidity);
        } catch (e: any) {
          throw new Error(`error posting humidity partition ${partitionKey}: ${e.message}`);
        }
      });
    }
  }

  // push temperature partitions
  if (record.temperaturePartitionKeys !== undefined) {
    let temperaturePartitionsChecks = await apiCheckMultiplePartitionsExist(record.temperaturePartitionKeys);
    for (let partitionKey of temperaturePartitionsChecks.Missing) {
      tasks.push(async () => {
        try {
          let partition = await getPartition(partitionKey);
          await apiPostPartition(partition, record.key, ApiParitionDataType.Temperature);
        } catch (e: any) {
          throw new Error(`error posting temperature partition ${partitionKey}: ${e.message}`);
        }
      });
    }
  }

  try {
    await Promise.all(tasks.map((task) => task()));
  } catch (e: any) {
    console.error('error posting all record partitions', e.message);
  }
};

// push sensorgrams partitions in batches
// no need to push them one by one if partitions are really small !
export const apiPostRecordPartitionsInBatches = async (record: RecordValue) => {
  if (!record.sensogramPartitionKeys) {
    return;
  }

  let missingPartitions: string[] = [];
  let missingPartitionsDataType: ApiParitionDataType[] = [];
  let sensorgramPartitionsChecks = await apiCheckMultiplePartitionsExist(record.sensogramPartitionKeys);
  missingPartitions = missingPartitions.concat(sensorgramPartitionsChecks.Missing);
  missingPartitionsDataType = missingPartitionsDataType.concat(Array(sensorgramPartitionsChecks.Missing.length).fill(ApiParitionDataType.Sensogram));

  if (record.humidityPartitionKeys !== undefined) {
    let humidityPartitionsChecks = await apiCheckMultiplePartitionsExist(record.humidityPartitionKeys);
    missingPartitions = missingPartitions.concat(humidityPartitionsChecks.Missing);
    missingPartitionsDataType = missingPartitionsDataType.concat(Array(humidityPartitionsChecks.Missing.length).fill(ApiParitionDataType.Humidity));
  }

  if (record.temperaturePartitionKeys !== undefined) {
    let temperaturePartitionsChecks = await apiCheckMultiplePartitionsExist(record.temperaturePartitionKeys);
    missingPartitions = missingPartitions.concat(temperaturePartitionsChecks.Missing);
    missingPartitionsDataType = missingPartitionsDataType.concat(Array(temperaturePartitionsChecks.Missing.length).fill(ApiParitionDataType.Temperature));
  }

  if (missingPartitions.length > 0) {
    let partitionsToStack: Uint8Array[] = [];
    let totalLength = 0;

    for (let i = 0; i < missingPartitions.length; i++) {
      let partition = await getPartition(missingPartitions[i]);
      let encodedPartition = encodeApiPartition(partition, missingPartitionsDataType[i]);
      partitionsToStack.push(encodedPartition);
      totalLength += NB_BYTES_PARTITION_LENGTH_ENCODING;
      totalLength += encodedPartition.byteLength;

      if (totalLength > REQUEST_BODY_BYTE_SIZE_LIMIT) {
        // encode multiple partitions bytes
        let stackedPartitions = stackEncodedPartitions(partitionsToStack);

        // send and await request
        await apiPostEncodedStackedPartitions(stackedPartitions, record.key);

        // clean partitionsToMerge and totalLength
        totalLength = 0;
        partitionsToStack = [];
      }
    }

    // upload last partition
    let stackedPartitions = stackEncodedPartitions(partitionsToStack);
    await apiPostEncodedStackedPartitions(stackedPartitions, record.key);
  }
};

const _apiGetRecordPartitionKeys = async (recordKey: string, partitionDataType: ApiParitionDataType) => {
  let partitionKeys: string[] = await callAPIEndpoint(`/record/partition/ids?record_id=${recordKey}&partition_data_type=${partitionDataType}`, 'GET');
  return partitionKeys;
};

export const apiGetRecordSensogramPartitionKeys = async (recordKey: string) => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Sensogram);
};

export const apiGetRecordHumidityPartitionKeys = async (recordKey: string): Promise<string[] | null> => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Humidity);
};

export const apiGetRecordTemperaturePartitionKeys = async (recordKey: string): Promise<string[] | null> => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Temperature);
};

export const apiGetRecordPartition = async (partitionKey: string) => {
  let encodedPartition: ArrayBuffer = await callAPIEndpoint(`/partition?partition_id=${partitionKey}`, 'GET', undefined, ApiResponseType.ArrayBuffer);
  return decodeApiPartition(encodedPartition);
};

export const apiGetPartitions = async (partitionKeys: string[]) => {
  let encodedPartitions: ArrayBuffer = await callAPIEndpoint(`/partitions/read`, 'POST', JSON.stringify({ IDs: partitionKeys }), ApiResponseType.ArrayBuffer);
  console.log('encoded partitions are', encodedPartitions);
  return encodedPartitions;
};

export const apiGetAndSavePartitions = async (remoteRecordKey: string) => {
  let remoteRecordPartitionKeys = await apiGetRecordSensogramPartitionKeys(remoteRecordKey);
  let remoteRecordHumidityPartitionKeys = await apiGetRecordHumidityPartitionKeys(remoteRecordKey);
  let remoteRecordTemperaturePartitionKeys = await apiGetRecordTemperaturePartitionKeys(remoteRecordKey);

  let remoteAllPartitionKeys: string[] = [];
  remoteAllPartitionKeys = remoteAllPartitionKeys.concat(remoteRecordPartitionKeys);
  if (remoteRecordHumidityPartitionKeys !== null) {
    remoteAllPartitionKeys = remoteAllPartitionKeys.concat(remoteRecordHumidityPartitionKeys);
  }
  if (remoteRecordTemperaturePartitionKeys !== null) {
    remoteAllPartitionKeys = remoteAllPartitionKeys.concat(remoteRecordTemperaturePartitionKeys);
  }

  let stackedPartitions = await apiGetPartitions(remoteAllPartitionKeys);
  let partitions = unstackEncodedPartitions(stackedPartitions);
  for (let partition of partitions) {
    let remotePartition = decodeApiPartition(partition);
    await putPartition(remotePartition);
  }

  let returnKeys: ApiAllPartitionKeys = {
    sensorgramPartitionKeys: remoteRecordPartitionKeys,
    humidityPartitionKeys: remoteRecordHumidityPartitionKeys,
    temperaturePartitionKeys: remoteRecordTemperaturePartitionKeys,
  };

  return returnKeys;
};
