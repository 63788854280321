import { getEllipseSVGPath } from './ellipse';
import {
  ARYBALLE_COLOR_CYAN,
  ARYBALLE_COLOR_GRAY,
  ARYBALLE_COLOR_GRAY_DARK,
  colorHexToRGBA,
  colorTupleToRGBA,
  DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE,
  PEPTIDE_COLOR_MAP_VDW,
  rowIdxToLetter,
  spotsgrid1dIndexTo2dCoordinates,
} from '../../utils/helpers/utils';
import { transpose } from './utils';
import { Envelope, OdorDetectionInfos, signature } from './definitions';
import { DEFAULT_PLOTLY_LAYOUT, DEFAULT_PLOTLY_MARGIN, PEAK_RADIUS } from '../../utils/constants/constants';
import { PeakPositionsType } from '../serial/csm';
import { Shape } from 'plotly.js';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../serial/constants';

export const getPcaFigure = (cmap: Record<string, [number, number, number]>, pcaExplainedVarianceRatio: number[], groupedProjections: Record<string, number[][]>, scaledGroupedEllipses: Record<string, number[]>) => {
  const shapes: any[] = [];
  Object.entries(scaledGroupedEllipses).forEach(([group, ellipse]) => {
    const [cx, cy, a, b, theta] = ellipse;
    const path = getEllipseSVGPath(cx, cy, a, b, theta);
    shapes.push({
      type: 'path',
      path,
      fillcolor: colorTupleToRGBA(cmap[group], 0.1),
      line: {
        width: 1,
        color: colorTupleToRGBA(cmap[group], 1),
      },
    });
  });

  const data: Plotly.Data[] = [];
  const layout: Partial<Plotly.Layout> = {
    hovermode: 'closest',
    xaxis: {
      title: {
        text: 'PCA 1 (' + (100 * pcaExplainedVarianceRatio[0]).toFixed(1) + '%)',
        font: { size: 12 },
      },
      showticklabels: false,
    },
    yaxis: {
      title: {
        text: 'PCA 2 (' + (100 * pcaExplainedVarianceRatio[1]).toFixed(1) + '%)',
        font: { size: 12 },
      },
      scaleanchor: 'x',
      scaleratio: 1,
      showticklabels: false,
    },
    annotations: [],
    dragmode: 'zoom',
    shapes,
  };
  Object.entries(groupedProjections).forEach(([group, projs]) => {
    data.push({
      type: 'scatter',
      name: group,
      legendgroup: group,
      x: projs.map((e) => e[0]),
      y: projs.map((e) => e[1]),
      mode: 'markers',
      hoverinfo: 'text',
      text: projs.map((e, i) => `${group}_${i + 1}`),
      marker: {
        size: 10,
        line: {
          color: colorTupleToRGBA(cmap[group], 1),
          width: 2,
        },
        color: colorTupleToRGBA(cmap[group], 0.1),
      },
    });
  });
  return { data, layout };
};

export const getModeledPcaFigure = (cmap: Record<string, [number, number, number]>, groupedScaledEllipses: Record<string, number[]>) => {
  const shapes: any[] = [];
  Object.entries(groupedScaledEllipses).forEach(([group, ellipse]) => {
    const [cx, cy, a, b, theta] = ellipse;
    const path = getEllipseSVGPath(cx, cy, a, b, theta);
    shapes.push({
      type: 'path',
      path,
      fillcolor: colorTupleToRGBA(cmap[group], 0.1),
      line: {
        width: 1,
        color: colorTupleToRGBA(cmap[group], 1),
      },
    });
  });

  const data: Plotly.Data[] = [];

  let _minX = 1e6;
  let _maxX = -1e6;
  let _minY = 1e6;
  let _maxY = -1e6;

  Object.values(groupedScaledEllipses).forEach((ellipse) => {
    const [cx, cy, a, b, theta] = ellipse;
    let r = Math.sqrt(a * a + b * b);
    if (cx - r < _minX) _minX = cx - r;
    if (cx + r > _maxX) _maxX = cx + r;
    if (cy - r < _minY) _minY = cy - r;
    if (cy + r > _maxY) _maxY = cy + r;
  });

  const layout: Partial<Plotly.Layout> = {
    hovermode: 'closest',
    xaxis: {
      title: {
        text: 'PCA 1',
        font: { size: 9 },
      },
      range: [_minX, _maxX],
      showticklabels: false,
    },
    yaxis: {
      title: {
        text: 'PCA 2',
        font: { size: 9 },
      },
      scaleanchor: 'x',
      scaleratio: 1,
      range: [_minY, _maxY],
      showticklabels: false,
    },
    annotations: [],
    dragmode: 'zoom',
    shapes,
  };
  return { data, layout };
};

export const addPointToPcaFigure = (
  pcaFig: {
    data: Plotly.Data[];
    layout: Partial<Plotly.Layout>;
  },
  point: [number, number],
  label: string,
  cmap: Record<string, [number, number, number]>
) => {
  let color = 'red';
  if (Object.keys(cmap).includes(label)) {
    color = colorTupleToRGBA(cmap[label], 1);
  }
  pcaFig.data.push({
    type: 'scatter',
    name: label,
    legendgroup: 'test_points',
    x: [point[0]],
    y: [point[1]],
    mode: 'markers',
    marker: {
      color,
      size: 10,
      symbol: 'square',
    },
  });
  return pcaFig;
};

export const getSignaturesTable = (spotfile: string[], signatures: signature[], numericLabels: number[]): { data: any[]; layout: any } => ({
  data: [
    {
      type: 'table',
      header: {
        values: spotfile.concat(['label']),
        fill: {
          color: Array(signatures[0].length).fill('eee').concat(['#1193f5']),
        },
      },
      cells: {
        values: transpose(signatures.map((e, i) => e.concat([numericLabels[i]]))),
        format: Array(signatures[0].length).fill('.4f').concat([null]),
      },
    },
  ],
  layout: {},
});

export const getSignaturesFigure = (spotsgrid1d: number[], signatures: signature[], labels: string[], cmap: Record<string, [number, number, number]>) => {
  // Sort spots in nature ascending order
  // const { sortedSpotfile, sortedSignatures } = sortSignatures(spotfile, signatures);
  let spotfileStr = spotsgrid1d.map((e) => '&nbsp;' + e.toString());
  let data: Plotly.Data[] = signatures.map((sig, i) => ({
    type: 'scatterpolar',
    name: `${labels[i]}_${i + 1}`,
    legendgroup: labels[i],
    theta: spotfileStr.concat(spotfileStr.slice(0, 1)),
    r: sig.concat(sig.slice(0, 1)),
    line: {
      color: colorTupleToRGBA(cmap[labels[i]], 1),
    },
  }));

  let _min: number = 1e6;
  let _max: number = -1e6;
  signatures.forEach((sig) => {
    sig.forEach((e) => {
      if (e < _min) _min = e;
      if (e > _max) _max = e;
    });
  });

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        // range: [_min, _max],
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
  };
  return {
    data,
    layout,
  };
};

export const getSignatureFigure = (spotsgrid1d: number[], signature: signature, color?: string) => {
  let spotfileStr = spotsgrid1d.map((e) => '&nbsp;' + e.toString());
  let signatureFixed = signature.map((e) => parseFloat(e.toFixed(3)));
  let data: Plotly.Data[] = [
    {
      type: 'scatterpolar',
      theta: spotfileStr.concat(spotfileStr.slice(0, 1)),
      r: signatureFixed.concat(signatureFixed.slice(0, 1)),
      fill: 'toself',
      hoverinfo: 'y+x',
      hoveron: 'points',
      line: {
        color: color || '#202A44',
      },
    },
  ];

  let _min = Math.min(...signatureFixed);
  let _max = Math.max(...signatureFixed);

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
    margin: {
      t: 20,
      b: 20,
      l: 30,
      r: 30,
    },
  };
  return {
    data,
    layout,
  };
};

export const getFigureFromGenericModel = (distances: { [key: string]: number }, color?: string) => {
  // Extract the keys into an array of strings
  // const classesFromModel: string[] = Object.keys(distances);
  //const computedProbabilities: number[] = Object.values(distances).map(value => parseFloat(value.toFixed(2)));

  // convert to array
  var distancesArray = Object.keys(distances).map((key) => {
    return { key: key, value: distances[key] };
  });

  distancesArray.sort((a, b) => a.value - b.value);

  let computedProbabilities = distancesArray.map((x) => x.value);
  let classesFromModel = distancesArray.map((x) => x.key);

  let data: Plotly.Data[] = [
    {
      type: 'bar',
      x: computedProbabilities,
      y: classesFromModel,
      orientation: 'h',
      hoverinfo: 'y+x',
      hoveron: 'points',
      line: {
        color: color || '#202A44',
      },
    },
  ];

  let _min = Math.min(...computedProbabilities);
  let _max = Math.max(...computedProbabilities);

  let layout: Partial<Plotly.Layout> = {
    polar: {
      angularaxis: {
        showgrid: false,
        showline: true,
        showticklabels: true,
        ticks: '',
      },
      radialaxis: {
        range: [_min, _max],
        showgrid: true,
        showline: true,
        showticklabels: true,
        ticks: 'outside',
      },
    },
    margin: {
      t: 20,
      b: 20,
      l: 100,
      r: 30,
    },
  };
  return {
    data,
    layout,
  };
};

/**
 * Generates a Plotly figure from a 2D array of image data.
 *
 * This function processes a 2D array of numbers representing an image's pixel data and returns an object containing both:
 *   - `data` (the plot data for rendering the image)
 *   - `layout` (the layout configuration for the Plotly figure).
 *
 * Optionally, you can choose whether to copy the underlying image data.
 *
 * @param {number[][]} imageArray - A 2D array representing the image's pixel data, where each entry in the
 *                                   array corresponds to a pixel's intensity in the range 0-255.
 *                                   This could be an array of grayscale intensities or color values (like RGB).
 * @param {boolean} [copyUnderlyingArray=true] - A flag indicating whether to create a copy of the `imageArray` or use it directly.
 *                                               If true, a new copy of the image array will be made, which will always trigger a rendering of the plot;
 *                                               otherwise, the original array is used and in that case no Plot rendering will be triggered !
 *
 * @returns {{ data: Plotly.Data[]; layout: Partial<Plotly.Layout>; }} An object containing the following:
 *   - `data`: An array of Plotly data objects for rendering the image. This will typically include
 *             a heatmap plot based on the `imageArray`.
 *   - `layout`: A partial configuration object for the layout of the Plotly figure, which can include
 *              title, axis labels, and other display settings.
 */
export const getImageFigure = (imageArray: number[][], peakPositions: PeakPositionsType | undefined = undefined, copyUnderlyingArray = true) => {
  // Placeholder to hold the Plotly data object
  let data: Plotly.Data[] = [
    {
      z: copyUnderlyingArray ? [...imageArray] : imageArray, // Copy image data if flag is true
      colorscale: 'Greys', // blackscale image is being plotted here
      type: 'heatmap', // Define the plot type as a heatmap, suitable for displaying image data
      zmin: 0,
      zmax: 255,
      showscale: false,
    },
  ];

  const shapes: Partial<Shape>[] = [];
  if (peakPositions !== undefined) {
    // data.push({
    //   x: peakPositions.x,
    //   y: peakPositions.y,
    //   type: 'scatter',
    //   text: new Array(192).fill('t'),
    //   textfont: {
    //     size: 10,
    //     color: 'white',
    //   },
    //   textposition: 'top center',
    //   mode: 'text+markers',
    //   marker: {
    //     size: 3,
    //   },
    // });

    for (let ipeak = 0; ipeak < peakPositions.x.length; ipeak++) {
      let x = peakPositions.x[ipeak];
      let y = peakPositions.y[ipeak];
      // spot = ipeak // 3
      // phase = ipeak % 3
      // peak_names.append(spot_order_to_coordinate(spot, phase))
      shapes.push({
        type: 'circle',
        xref: 'x',
        yref: 'y',
        x0: x - PEAK_RADIUS,
        y0: y - PEAK_RADIUS,
        x1: x + PEAK_RADIUS,
        y1: y + PEAK_RADIUS,
        line: {
          width: 1,
          color: 'yellow',
        },
      });
    }
  }

  let layout: Partial<Plotly.Layout> = {
    xaxis: {
      showticklabels: false,
      side: 'top',
      ticks: '',
      title: 'x (pixel)',
    },
    yaxis: {
      autorange: 'reversed',
      showticklabels: false,
      ticks: '',
      title: 'y (pixel)',
    },
    margin: DEFAULT_PLOTLY_MARGIN,
    showlegend: false,
    shapes: shapes,
  };

  return { data, layout };
};

export const getMziFigureOptions = (seriesLabels: number[], aggregatedSeries: boolean, signalEnvelope: Envelope, odorState: OdorDetectionInfos, mziTooltip: HTMLDivElement | null) => {
  // build uplot options
  const opts: uPlot.Options = {
    id: `uplot-chart-mmi`,
    width: 0,
    height: 0,
    padding: [0, 50, 0, 0],
    legend: {
      show: false,
    },
    scales: {
      y: {
        range: (u, min, max) => {
          const _max = max > 1 ? max : 1;
          const _min = min < -1 ? min : -1;
          return [_min, _max];
        },
      },
      RH: {
        range: (u, min, max) => {
          return [min - 1, max + 1];
        },
      },
    },
    axes: [
      {}, // X axis
      {
        scale: 'y',
        label: 'Phase Shift (rad)',
      },
      {
        side: 1,
        scale: 'RH',
        label: 'Humidity (%RH)',
      },
    ],
    pxAlign: 0,
    series: [
      {},
      // sensogram series
      ...seriesLabels.map((spotInt) => {
        let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
        let peptideInt = spotInt;
        if (peptideInt < 0) {
          peptideInt *= -1;
        }
        let peptideStr = spotInt.toString();
        if (peptideStr.length === 3 && peptideStr[2] === '4') {
          peptideInt = parseInt(peptideStr.slice(0, 2));
        }
        if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
          color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
        }
        let label = peptideInt.toString();
        return {
          show: spotInt >= 1,
          spanGaps: false,
          label: label,
          stroke: color,
          width: 2,
        } as uPlot.Series;
      }),
      // Avg series
      {
        show: true,
        spanGaps: false,
        label: 'Avg',
        stroke: 'rgba(0,0,0,0.7)',
        width: 2,
      } as uPlot.Series,
      // humidity series
      {
        scale: 'RH',
        label: 'Humidity',
        stroke: 'rgba(0,128,255,0.7)',
        width: 2,
        dash: [10, 5],
      } as uPlot.Series,
    ],
    hooks: {
      setSeries: [
        (u, seriesIdx) => {
          try {
            // console.log("sense page: set series", seriesIdx)
            if (mziTooltip === null) {
              return;
            }
            if (seriesIdx === null) {
              return;
            }
            let seriesLabel = u.series[seriesIdx].label;
            if (seriesLabel === undefined) {
              return;
            }
            let peptideInt = parseInt(seriesLabel);
            let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
            if (seriesLabel === 'Avg') {
              color = 'rgba(0,0,0,0.7)';
            }
            if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
              color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
            }
            let tooltip = seriesLabel;
            if (!aggregatedSeries) {
              let [row, col] = spotsgrid1dIndexTo2dCoordinates(seriesIdx - 1);
              let rowStr = rowIdxToLetter(row);
              tooltip += ` [${rowStr}${col}]`;
            }
            mziTooltip.innerHTML = `<b>${tooltip}</b>`;
            mziTooltip.style.backgroundColor = color;
            mziTooltip.style.color = 'white';
            mziTooltip.style.border = '1px solid white';
            mziTooltip.style.borderRadius = '5px';
            mziTooltip.style.padding = '5px';
          } catch (e) {
            console.log('sense page: set series error', e);
          }
        },
      ],
      draw: [
        // draw horizontal line at noize level
        (u) => {
          const ctx = u.ctx;
          const x0 = u.bbox.left;
          const x1 = u.bbox.left + u.bbox.width;
          const y = u.valToPos(odorState.noizeLevel, 'y', true);
          ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY, 1);
          ctx.lineWidth = 2;
          ctx.setLineDash([20, 5, 5, 5]);
          ctx.beginPath();
          ctx.moveTo(x0, y);
          ctx.lineTo(x1, y);
          ctx.stroke();
        },
        // draw signal envelope and indicate average
        (u) => {
          const ctx = u.ctx;
          const yMax = u.valToPos(signalEnvelope.max, 'y', true);
          const yAvg = u.valToPos(signalEnvelope.avg, 'y', true);
          const yMin = u.valToPos(signalEnvelope.min, 'y', true);
          let txt = signalEnvelope.avg.toFixed(2);
          let txtWidth = ctx.measureText(txt).width;
          const x = u.bbox.left + u.bbox.width + 20;

          ctx.font = '24px sans-serif';
          ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
          ctx.fillText(signalEnvelope.avg.toFixed(2), x + txtWidth + 20, yAvg);

          // ctx.canvas.width = ctx.canvas.width + txtWidth + 20

          let bracketWidth = 5;
          let pointerWidth = 10;

          ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
          ctx.lineWidth = 2;
          ctx.setLineDash([1, 0]);
          ctx.beginPath();
          ctx.moveTo(x, yMax);
          ctx.lineTo(x + bracketWidth, yMax);
          ctx.lineTo(x + bracketWidth, yAvg);
          ctx.lineTo(x + bracketWidth + pointerWidth, yAvg);
          ctx.moveTo(x + bracketWidth, yAvg);
          ctx.lineTo(x + bracketWidth, yMin);
          ctx.lineTo(x, yMin);
          ctx.stroke();
        },

        // draw odor presence "corridor"
        (u) => {
          if (!odorState.isOdorPresent) {
            return;
          }
          const ctx = u.ctx;
          const x0 = u.bbox.left;
          const x1 = u.bbox.left + u.bbox.width;
          const y0 = u.valToPos(Math.max(odorState.maxIntensity, signalEnvelope.max), 'y', true);
          const y1 = u.valToPos(Math.max(odorState.maxIntensity * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorState.thresholdLevel), 'y', true);
          ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
          ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
          ctx.lineWidth = 3;
          ctx.setLineDash([10, 5]);
          ctx.beginPath();
          ctx.moveTo(x0, y0);
          ctx.lineTo(x1, y0);
          ctx.moveTo(x1, y1);
          ctx.lineTo(x0, y1);
          ctx.fillRect(x0, y1, x1 - x0, y0 - y1);
          ctx.stroke();
        },
        // draw odor presence start and stop timestamps
        (u) => {
          const ctx = u.ctx;
          ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
          ctx.lineWidth = 3;
          ctx.setLineDash([10, 5]);
          const yBot = u.bbox.top + u.bbox.height;
          const yTop = u.bbox.top;
          if (odorState.startTimestamp > 0) {
            const startX = u.valToPos(odorState.startTimestamp / 1000, 'x', true);
            ctx.beginPath();
            ctx.moveTo(startX, yBot);
            ctx.lineTo(startX, yTop);
            ctx.stroke();
          }
          if (odorState.stopTimestamp > 0) {
            const stopX = u.valToPos(odorState.stopTimestamp / 1000, 'x', true);
            ctx.beginPath();
            ctx.moveTo(stopX, yBot);
            ctx.lineTo(stopX, yTop);
            ctx.stroke();
          }
          if (odorState.startTimestamp > 0 && odorState.stopTimestamp > 0) {
            const startX = u.valToPos(odorState.startTimestamp / 1000, 'x', true);
            const stopX = u.valToPos(odorState.stopTimestamp / 1000, 'x', true);
            ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
            ctx.fillRect(startX, yBot, stopX - startX, yTop - yBot);
          }
        },
      ],
    },
    focus: {
      alpha: 0.3,
    },
    cursor: {
      focus: {
        prox: 10,
      },
    },
  };
  return opts;
};
