import { RouteProps } from 'react-router-dom';
import { HomePage } from '../pages/HomePage';
import { ProfilePage } from '../pages/ProfilePage';
import Monitoring from '../pages/Monitoring';
import { NoaDevicePage } from '../pages/DevicePage/NoaDevicePage';
import { CsmDevicePage } from '../pages/DevicePage/CsmDevicePage';
import { NoaConnectPage } from '../pages/ConnectPage/NoaConnectPage';
import { CsmBleConnectPage } from '../pages/ConnectPage/CsmBleConnectPage';
import { NoaOdorIdentification } from '../pages/OdorIdentificationPage/NoaOdorIdentificationContainer';
import { CsmOdorIdentification } from '../pages/OdorIdentificationPage/CsmOdorIdentificationContainer';
import { NoaSensePage } from '../pages/SensePage/NoaSensePage';
import { CsmSensePage } from '../pages/SensePage/CsmSensePage';
import { Record } from '../pages/ModelsManagementPage/Records/Record';
import { ModelsManagementPage } from '../pages/ModelsManagementPage/ModelsManagementPage';
import { NoaDebugPage } from '../pages/DebugPage/NoaDebugPage';
import { CsmDebugPage } from '../pages/DebugPage/CsmDebugPage';
import { LoginCallback } from '@okta/okta-react';
import { ControlOutlined, FolderOpenOutlined, FundViewOutlined, HomeOutlined } from '@ant-design/icons';
import { isBleApp } from '../utils/helpers/utils';

interface AppRoute extends RouteProps {
  menuGroup?: string;
  showInMenu?: boolean;
  menuLabel?: string;
  icon?: React.ReactNode;
  key?: string;
}

const HOME_PATH = '/';
const PROFILE_PATH = '/profile';
const MONITORING_PATH = '/Monitoring/ble';
const NOA_DEVICE_PATH = '/device/serial';
const CSM_DEVICE_PATH = '/device/ble';
const NOA_CONNECT_PATH = '/connect/serial';
const CSM_BLE_CONNECT_PAGE = '/connect/ble';
const NOA_ODOR_IDENTIFICATION_PATH = '/odorIdentification/serial';
const CSM_ODOR_IDENTIFICATION_PATH = '/odorIdentification/ble';
const NOA_SENSE_PATH = '/sense/serial';
const CSM_SENSE_PATH = '/sense/ble';
const RECORD_PATH = '/records/:recordId';
const MODELS_MANAGEMENT_PATH = '/records';
const NOA_DEBUG_PATH = '/debug/serial';
const CSM_DEBUG_PATH = '/debug/ble';
const LOGIN_CALLBACK_PATH = '/signin/callback';

export const getPath = (CSM: string, NOA: string) => (isBleApp() ? CSM : NOA);
export const getComponent = (CSM: React.FC, NOA: React.FC) => (isBleApp() ? CSM : NOA);

export const HOME_ROUTE = { path: HOME_PATH, exact: true, component: HomePage, showInMenu: true, menuLabel: 'Home', icon: <HomeOutlined />, key: 'key-menu-home' };
export const SENSE_ROUTE = { path: getPath(CSM_SENSE_PATH, NOA_SENSE_PATH), component: getComponent(CsmSensePage, NoaSensePage), showInMenu: true, menuLabel: 'Sense', icon: <FundViewOutlined />, key: 'key-menu-sense' };
export const MODELS_MANAGEMENT_ROUTE = { path: MODELS_MANAGEMENT_PATH, component: ModelsManagementPage, showInMenu: true, menuLabel: 'Records', icon: <FolderOpenOutlined />, key: 'key-menu-records' };
export const DEVICE_ROUTE = { path: getPath(CSM_DEVICE_PATH, NOA_DEVICE_PATH), component: getComponent(CsmDevicePage, NoaDevicePage), showInMenu: true, menuLabel: 'Device', icon: <ControlOutlined />, key: 'key-menu-device' };
export const PROFILE_ROUTE = { path: PROFILE_PATH, component: ProfilePage };
export const MONITORING_ROUTE = { path: MONITORING_PATH, component: Monitoring };
export const CONNECT_ROUTE = { path: getPath(CSM_BLE_CONNECT_PAGE, NOA_CONNECT_PATH), component: getComponent(CsmBleConnectPage, NoaConnectPage) };
export const ODOR_IDENTIFICATION_ROUTE = { path: getPath(CSM_ODOR_IDENTIFICATION_PATH, NOA_ODOR_IDENTIFICATION_PATH), component: getComponent(CsmOdorIdentification, NoaOdorIdentification) };
export const RECORD_ROUTE = { path: RECORD_PATH, component: Record };
export const DEBUG_ROUTE = { path: getPath(CSM_DEBUG_PATH, NOA_DEBUG_PATH), component: getComponent(CsmDebugPage, NoaDebugPage) };
export const LOGIN_CALLBACK_ROUTE = { path: LOGIN_CALLBACK_PATH, component: LoginCallback };

export const routes: AppRoute[] = [HOME_ROUTE, SENSE_ROUTE, RECORD_ROUTE, MODELS_MANAGEMENT_ROUTE, DEVICE_ROUTE, PROFILE_ROUTE, MONITORING_ROUTE, CONNECT_ROUTE, ODOR_IDENTIFICATION_ROUTE, DEBUG_ROUTE, LOGIN_CALLBACK_ROUTE];
