import { Divider, Typography } from 'antd';
import { FlexCol, Paper } from '../../../components/common/common';
import { BugOutlined } from '@ant-design/icons';
import { DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_RAW_FPS } from '../../../utils/constants/constants';
import { HIHvalues } from '../../../types/types';

type DebugInfoComponenentProps = {
  showDebugInfo: boolean;
  rawFps: number;
  decimatedFps: number;
  hihValues: HIHvalues;
};

const DebugInfoComponenent: React.FC<DebugInfoComponenentProps> = ({ showDebugInfo, rawFps, decimatedFps, hihValues }) => {
  return (
    <>
      {showDebugInfo && (
        <FlexCol style={{ width: '100%' }}>
          <Divider>
            <BugOutlined />
          </Divider>
          <FlexCol style={{ width: '100%' }}>
            <Paper style={{ width: '100%', height: '100%' }}>
              <FlexCol>
                <Typography.Title level={5}>Signal jitter</Typography.Title>
                <FlexCol style={{ gap: 2, fontSize: 11 }}>
                  <p>
                    <span style={{ color: 'red' }}>Red</span>: raw FPS (as received from the device): <b>{rawFps.toFixed(0)}</b>
                  </p>
                  <p>
                    <span style={{ color: 'blue' }}>Blue</span>: decimated FPS: <b>{decimatedFps.toFixed(1)}</b>
                  </p>
                  <p>
                    <span style={{ color: 'green' }}>Green</span>: HIH humidity value: <b>{hihValues.humidity.toFixed(2)}</b>
                  </p>
                  <p>
                    <span style={{ color: 'green' }}>Green</span>: HIH temperature value: <b>{hihValues.temperature.toFixed(2)}</b>
                  </p>
                </FlexCol>
              </FlexCol>
            </Paper>
          </FlexCol>
        </FlexCol>
      )}
    </>
  );
};

export default DebugInfoComponenent;
