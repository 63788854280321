import { gql } from '@apollo/client';
import { HIHvalues } from '../../types/types';
import { client } from './client';

const PUSH_SENSOR_DATA = gql`
  mutation PushExternalOdorSensorData($data: ExternalOdorSensorData!) {
    pushExternalOdorSensorData(data: $data)
  }
`;

export const pushSensorDataToRunner = async (mzis: number[], hihValues: HIHvalues) => {
  const mzi_data = mzis;
  const current_humidity = hihValues.humidity;
  const current_temperature = hihValues.temperature;

  try {
    await client.mutate({
      mutation: PUSH_SENSOR_DATA,
      variables: {
        data: {
          value: mzi_data,
          timestamp: Date.now(),
          humidity: current_humidity / 100,
          temperature: current_temperature,
        },
      },
    });
    console.log('Data sent successfully');
  } catch (error) {
    console.error('Error sending data:', error);
    console.log('Failed to send data');
  }
};
