import { BoxPlotOutlined, BoxPlotTwoTone } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type SensorAggregationProps = {
  shouldAggregate: boolean;
  setMziUplotOptions: React.Dispatch<React.SetStateAction<uPlot.Options | null>>;
  setMziUplotData: React.Dispatch<React.SetStateAction<uPlot.AlignedData>>;
  setShouldAggregate: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRedraw: React.Dispatch<React.SetStateAction<boolean>>;
};

const SensorAggregation: React.FC<SensorAggregationProps> = ({ shouldAggregate, setMziUplotOptions, setMziUplotData, setShouldAggregate, setShouldRedraw }) => {
  return (
    <Tooltip
      overlayInnerStyle={{
        textAlign: 'center',
      }}
      overlay="Toggle sensor aggregation"
    >
      <Button
        type="text"
        size="large"
        onClick={() => {
          setMziUplotOptions(null);
          setMziUplotData([]);
          setShouldAggregate(!shouldAggregate);
          setShouldRedraw(true);
        }}
        icon={shouldAggregate ? <BoxPlotTwoTone /> : <BoxPlotOutlined />}
      />
    </Tooltip>
  );
};

export default SensorAggregation;
