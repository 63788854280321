import { Radio, Tooltip } from 'antd';
import { FlexRow } from '../../../components/common/common';
import { QuestionCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';

enum SenseMode {
  Recording,
  Questionning,
}
type SenseTabProps = {
  isRecording: boolean;
  senseMode: SenseMode;
  setSenseMode: React.Dispatch<React.SetStateAction<SenseMode>>;
};

const SenseTab: React.FC<SenseTabProps> = ({ isRecording, senseMode, setSenseMode }) => {
  return (
    <FlexRow
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <Radio.Group
        disabled={isRecording}
        optionType="button"
        buttonStyle="solid"
        size="large"
        value={senseMode}
        options={[
          {
            label: (
              <>
                <Tooltip overlay="Record samples for odor analysis and model training">
                  <VideoCameraOutlined /> Recording
                </Tooltip>
              </>
            ),
            value: SenseMode.Recording,
          },
          {
            label: (
              <>
                <Tooltip overlay="Question unknown samples for odor recognition">
                  <QuestionCircleOutlined /> Recognition
                </Tooltip>
              </>
            ),
            value: SenseMode.Questionning,
          },
        ]}
        onChange={(e) => {
          setSenseMode(e.target.value);
        }}
      />
    </FlexRow>
  );
};

export default SenseTab;
