import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CSM_PROTOCOL_COMMAND_TYPE } from '../../../components/serial/csm';
import { useMessageContext } from '../../../state/context/MessageContext';
import { v4 as uuidv4 } from 'uuid';

type SensingButtonProps = {
  isSensing: boolean;
  setIsSensing: React.Dispatch<React.SetStateAction<boolean>>;
};

const SensingButton: React.FC<SensingButtonProps> = ({ isSensing, setIsSensing }) => {
  const { addCSMCommand } = useMessageContext();

  return (
    <Button
      block
      onClick={async () => {
        setIsSensing(!isSensing);
        addCSMCommand({
          id: uuidv4().toString(),
          message: { CmdType: isSensing ? CSM_PROTOCOL_COMMAND_TYPE.StopSampling : CSM_PROTOCOL_COMMAND_TYPE.StartSampling },
        });
      }}
      type={isSensing ? 'default' : 'primary'}
    >
      {isSensing ? (
        <>
          <LoadingOutlined spin /> Stop sensing
        </>
      ) : (
        'Start sensing'
      )}
    </Button>
  );
};

export default SensingButton;
