import { Button, Tooltip } from 'antd';

enum SenseMode {
  Recording,
  Questionning,
}

type RecognitionButtonProps = {
  senseMode: SenseMode;
  constructSignatureAndRecognize: (idxStart?: number) => void;
};

const RecognitionButton: React.FC<RecognitionButtonProps> = ({ senseMode, constructSignatureAndRecognize }) => {
  return (
    <>
      {senseMode === SenseMode.Questionning && (
        <Tooltip overlayInnerStyle={{ textAlign: 'center' }} overlay={<span>Takes a few last seconds of signal and tries to recognize the odor</span>}>
          <Button block type="primary" onClick={() => constructSignatureAndRecognize()}>
            Recognize!
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default RecognitionButton;
