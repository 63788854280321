import { LoadingOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { RecordNameModalDialog } from './RecordNameModalDialog';
import { DeviceValue, handleCommitPartitions, newRecord } from '../../../services/cache/idb';
import { useState } from 'react';
import { FlexCol } from '../../../components/common/common';

enum SenseMode {
  Recording,
  Questionning,
}

type RecordingButtonProps = {
  senseMode: SenseMode;
  recordKeyRef: React.MutableRefObject<string | null>;
  decimatedMZIPartitionSeriesRef: React.MutableRefObject<number[][]>;
  decimatedTimestampPartitionSeriesRef: React.MutableRefObject<number[]>;
  decimatedHumidityPartitionSeriesRef: React.MutableRefObject<number[]>;
  decimatedTemperatureSeriesRef: React.MutableRefObject<number[]>;
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  isRecording: boolean;
  deviceValue: DeviceValue | null;
};

const RecordingButton: React.FC<RecordingButtonProps> = (props) => {
  const { senseMode, recordKeyRef, decimatedMZIPartitionSeriesRef, decimatedTimestampPartitionSeriesRef, decimatedHumidityPartitionSeriesRef, decimatedTemperatureSeriesRef, setIsRecording, isRecording, deviceValue } = props;
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);
  const [recordStartTimestamp, setRecordStartTimestamp] = useState<number>(0);

  const startRecording = async () => {
    try {
      if (deviceValue === null) {
        throw new Error('device value is null');
      }
      let recordKey = await newRecord(deviceValue);
      console.info('sense page: start recording: record key', recordKey);
      recordKeyRef.current = recordKey;
      decimatedMZIPartitionSeriesRef.current = [];
      decimatedTimestampPartitionSeriesRef.current = [];
      decimatedHumidityPartitionSeriesRef.current = [];
      decimatedTemperatureSeriesRef.current = [];
      setIsRecording(true);
      setRecordStartTimestamp(Date.now());
    } catch (e: any) {
      console.log('sense page: could not start recording', e);
      if (e && e.message) {
        message.error('Could not start recording: ' + e);
      }
    }
  };

  const stopRecording = async () => {
    if (recordKeyRef.current === null) {
      console.log('sense page: record key is null');
      return;
    }
    console.info('sense page: stop recording: record key', recordKeyRef.current);
    console.log('sense page: stop recording: about to commit the last partition', decimatedMZIPartitionSeriesRef.current);
    handleCommitPartitions(recordKeyRef.current, decimatedTimestampPartitionSeriesRef.current, decimatedMZIPartitionSeriesRef.current, decimatedHumidityPartitionSeriesRef.current, decimatedTemperatureSeriesRef.current);
    decimatedMZIPartitionSeriesRef.current = [];
    decimatedTimestampPartitionSeriesRef.current = [];
    decimatedHumidityPartitionSeriesRef.current = [];
    decimatedTemperatureSeriesRef.current = [];

    setIsRecording(false);
    setIsRecordModalOpen(true);
    setRecordStartTimestamp(0);
  };

  const renderElapsedTime = () => {
    let elapsed = Date.now() - recordStartTimestamp;
    let elapsedSeconds = Math.floor(elapsed / 1000);
    let elapsedMinutes = Math.floor(elapsedSeconds / 60);
    let elapsedHours = Math.floor(elapsedMinutes / 60);

    let elapsedMinutesMod = elapsedMinutes % 60;
    let elapsedSecondsMod = elapsedSeconds % 60;

    let elpasedString = '';
    if (elapsedHours > 0) {
      elpasedString += `${elapsedHours}h `;
    }
    if (elapsedMinutes > 0) {
      elpasedString += `${elapsedMinutesMod}m `;
    }
    if (elapsedSeconds > 0) {
      elpasedString += `${elapsedSecondsMod}s`;
    }
    return elpasedString;
  };

  return (
    <>
      {senseMode === SenseMode.Recording && (
        <>
          <Button block onClick={() => (isRecording ? stopRecording() : startRecording())} type={isRecording ? 'default' : 'primary'}>
            {isRecording ? (
              <>
                <LoadingOutlined spin /> Stop recording
              </>
            ) : (
              'Start recording'
            )}
          </Button>
          <RecordNameModalDialog isOpen={isRecordModalOpen} setIsOpen={setIsRecordModalOpen} recordKey={recordKeyRef.current} />
        </>
      )}

      {isRecording && (
        <FlexCol style={{ textAlign: 'left', gap: 1, marginTop: 20 }}>
          <span>🔴 Recording... {renderElapsedTime()}</span>
          <span>
            <i>
              <b>Note</b>: leaving this page will stop the recording (saving what is recorded so far)
            </i>
          </span>
        </FlexCol>
      )}
    </>
  );
};

export default RecordingButton;
