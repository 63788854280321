import { HIHvalues, SignatureWithSpotgrid } from '../../types/types';

import { correctHumidity } from './compute';

/*
functions to handle MZI calculations
*/
export const mmi2mzi = (mmi: number[]): number[] => {
  let mzi: number[] = [];
  for (let i = 0; i < mmi.length; i += 3) {
    let p1 = mmi[i];
    let p2 = mmi[i + 1];
    let p3 = mmi[i + 2];
    let it = 2 * p2 - p1 - p3;
    let qt = Math.sqrt(3) * (p1 - p3);
    let phase = -Math.atan2(qt, it);
    mzi.push(phase);
  }
  return mzi;
};

// returns corrected MZIs and new Ks
export const correctPhaseShifts = (newMZIs: number[], previousMZIs: number[], previousKs: number[]): [number[], number[]] => {
  let correctedMZIs: number[] = [];
  let newKs: number[] = [];
  for (let i = 0; i < newMZIs.length; i++) {
    let mzi = newMZIs[i];
    const previousMzi = previousMZIs[i];
    let k = previousKs[i];
    let diff = mzi - previousMzi;
    if (diff > Math.PI) {
      k -= 1;
      mzi -= 2 * Math.PI;
    } else if (diff < -Math.PI) {
      k += 1;
      mzi += 2 * Math.PI;
    }
    correctedMZIs.push(mzi);
    newKs.push(k);
  }
  return [correctedMZIs, newKs];
};

// Process the MZI data and calculate averages
export const processMziData = (
  rawMZISeriesRef: number[][],
  firstMZIsRef: number[] | null,
  currentSpotsgrid1d: number[] | null,
  humidityCompensationEnabled: boolean,
  humidityCalibrant: SignatureWithSpotgrid | undefined,
  humidityBaselineRef: number | null,
  hihValues: HIHvalues
): [number, number[], number[]] | undefined => {
  if (!currentSpotsgrid1d) {
    console.log('Spots grid is empty');
    return;
  }

  // calculate decimated MZI
  //console.log(rawMZISeriesRef.current)
  let decimatedMzis = calculateDecimatedMzis(currentSpotsgrid1d, rawMZISeriesRef);
  //console.log('mzi before applying corrections:', decimatedMzis)
  if (decimatedMzis !== undefined) {
    // Apply baseline correction, humidity compensation
    let correctedMZI = applyCorrections(decimatedMzis, firstMZIsRef, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef, hihValues);

    // Finalize the signal envelope average
    let finalizedSignalEnvelope = finalizeSignalEnvelope(correctedMZI);

    // push to local storage for cases where computing is not requested

    return [finalizedSignalEnvelope, decimatedMzis, correctedMZI];
  }
};

// Calculate decimated MZI values by averaging over window size
export const calculateDecimatedMzis = (currentSpotsgrid1d: number[] | null, rawMZISeriesRef: number[][]) => {
  if (currentSpotsgrid1d !== null) {
    let decimatedMzis = new Array(currentSpotsgrid1d.length).fill(0);

    rawMZISeriesRef.forEach((mziSeries) => {
      mziSeries.forEach((mzi, index) => {
        decimatedMzis[index] += mzi;
      });
    });

    return decimatedMzis.map((sum) => sum / rawMZISeriesRef.length);
  }
};

// Apply corrections like humidity compensation
export const applyCorrections = (
  decimatedMzis: number[],
  firstMZIsRef: number[] | null,
  currentSpotsgrid1d: number[] | null,
  humidityCompensationEnabled: boolean,
  humidityCalibrant: SignatureWithSpotgrid | undefined,
  humidityBaselineRef: number | null,
  hihValues: HIHvalues
) => {
  //console.log('firstMZIsRef.current is:', firstMZIsRef.current)
  decimatedMzis.forEach((mzi, index) => {
    if (firstMZIsRef !== null) {
      decimatedMzis[index] -= firstMZIsRef[index];
    }
  });

  //console.log('humidityCompensationEnabled:', humidityCompensationEnabled)

  if (humidityCompensationEnabled && humidityCalibrant !== undefined && humidityBaselineRef !== null && currentSpotsgrid1d !== null) {
    return correctHumidity(decimatedMzis, hihValues.humidity - humidityBaselineRef, currentSpotsgrid1d, humidityCalibrant);
  } else {
    return decimatedMzis;
  }
};

// Finalize the signal envelope average and update state
const finalizeSignalEnvelope = (decimatedMzis: number[]) => {
  let lastFrame: number[] = decimatedMzis;
  //console.log('last frame is', lastFrame)
  let lastFrameSum: number = 0;
  for (let i = 0; i < lastFrame.length; i++) {
    lastFrameSum += lastFrame[i];
  }
  //console.log('finalMZIsSeries is', finalMZIsSeries)
  // building signalEnvelopeAvgRef
  let signalEnvelopeAvgRef = lastFrameSum / lastFrame.length;

  return signalEnvelopeAvgRef;
};
