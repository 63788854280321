import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type ResetToZeroProps = {
  firstMZIsRef: React.MutableRefObject<number[] | null>;
  onClickReset: () => void;
};

const ResetToZero: React.FC<ResetToZeroProps> = ({ firstMZIsRef, onClickReset }) => {
  return (
    <Tooltip overlay="Reset to zero">
      <Button type="text" size="large" onClick={onClickReset} icon={<VerticalAlignBottomOutlined />} />
    </Tooltip>
  );
};

export default ResetToZero;
