export const createEmpty2dArray = (length: number) => {
  return new Array(length).fill(null).map((x) => new Array());
};

export const createFilled2dArray = <Type>(width: number, height: number, filledValue: Type): Type[][] => {
  return new Array(height).fill([]).map((x) => new Array(width).fill(filledValue));
};

// helper to push element to an array with an array size constraint
// the first element of the array is dropped if inserting the new element makes the size go above the specified max length
export const pushSliding = <Type>(array: Type[], newElement: Type, maxLength: number) => {
  array.push(newElement);
  if (array.length > maxLength) {
    array.shift();
  }
};
