import { SettingOutlined, SettingTwoTone } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type GlobalParametersProps = {
  showGlobalParameters: boolean;
  setShowGlobalParameters: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalParameters: React.FC<GlobalParametersProps> = ({ showGlobalParameters, setShowGlobalParameters }) => {
  return (
    <Tooltip overlay="Toggle global parameters">
      <Button
        type="text"
        size="large"
        icon={showGlobalParameters ? <SettingTwoTone /> : <SettingOutlined />}
        onClick={() => {
          setShowGlobalParameters(!showGlobalParameters);
        }}
      />
    </Tooltip>
  );
};

export default GlobalParameters;
