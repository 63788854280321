import { FC, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, CheckboxProps, Typography } from 'antd';
import { FlexCol, FlexRow } from '../../components/common/common';
import { JsonView, defaultStyles } from 'react-json-view-lite';
import { CSMMisoFrame, CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, PeakPositionsType, parseBiosensorsSignalEvent, parseEventPayload, parsePeakPositionResponse } from '../../components/serial/csm';
import { useMessageContext } from '../../state/context/MessageContext';
import { mean } from '../../components/analysis/utils';
import { v4 as uuidv4 } from 'uuid';
import Plot from 'react-plotly.js';
import { DEFAULT_PLOTLY_CONFIG, IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_DEFAULT_BLACK } from '../../utils/constants/constants';
import { getImageFigure } from '../../components/analysis/figures';
import { createFilled2dArray } from '../../utils/helpers/array';

export const CsmDebugPage: FC = () => {
  const { csmIsConnected, csmFwVersion, csmMessages, consumeCSMMessage, addCSMCommand } = useMessageContext();

  const [misoResponse, setMisoResponse] = useState<CSMMisoFrame | null>(null);
  const [biosensorsAverageSignal, setBiosensorsAverageSignal] = useState<number | null>(null);

  const imageData = useRef<number[][]>([]);
  const peaksPositions = useRef<PeakPositionsType>();
  const [showPeaks, setShowPeaks] = useState<boolean>(false);

  const [plotlyImageData, setPlotlyImageData] = useState<Plotly.Data[]>();
  const [plotlyImageLayout, setPlotlyImageLayout] = useState<Partial<Plotly.Layout>>({});

  const onChangePeaks: CheckboxProps['onChange'] = (e) => {
    setShowPeaks(e.target.checked);

    // as the firmware is only recomputing the peak positions when swhitching on
    // there is no need to resend the command once the result has been received the first time
    if (e.target.checked && peaksPositions.current === undefined) {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetPeakMask,
        },
      });
    }
    console.log(`checked = ${e.target.checked}`);
  };

  useEffect(() => {
    for (let message of csmMessages) {
      let frame = message.message;
      if (frame.Type < 0x80) {
        consumeCSMMessage(message.id);
        setMisoResponse(frame);
        if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetPeakMask) {
          let peakPositionsNew = parsePeakPositionResponse(frame.Payload);

          // at the end of getImage, the firmware returns a fake 17 response with all positions set at 0
          // let's discard this
          if (Math.max(...peakPositionsNew.x) !== 0 && Math.max(...peakPositionsNew.y) !== 0) {
            peaksPositions.current = parsePeakPositionResponse(frame.Payload);
          }
        }
      } else {
        switch (frame.Type) {
          case CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent:
            consumeCSMMessage(message.id);
            try {
              let eventPayload = parseEventPayload(frame.Payload);
              let sensogram = parseBiosensorsSignalEvent(eventPayload.Data, csmFwVersion);
              let avg = mean(sensogram);
              setBiosensorsAverageSignal(avg);
            } catch (e: any) {
              console.log('error parsing biosensors signal event', e.message);
            }
            break;
          case CSM_PROTOCOL_EVENT_TYPE.ImageEvent:
            consumeCSMMessage(message.id);

            // decoding image array
            let imageView = new DataView(message.message.Payload.buffer);
            let nbLine = imageView.getUint16(1, true);
            let array = Array.from(message.message.Payload.subarray(5));

            // generating plot
            imageData.current[nbLine] = array;

            let imageFigure;
            if (showPeaks && peaksPositions.current !== undefined) {
              imageFigure = getImageFigure(imageData.current, peaksPositions.current);
            } else {
              imageFigure = getImageFigure(imageData.current);
            }
            setPlotlyImageData(imageFigure.data);
            setPlotlyImageLayout(imageFigure.layout);
            break;
        }
      }
    }
  }, [csmMessages]);

  useEffect(() => {
    // avoid plotting anything while image data hasn't been received
    if (imageData.current.length > 0) {
      let imageFigure;
      if (showPeaks && peaksPositions.current !== undefined) {
        imageFigure = getImageFigure(imageData.current, peaksPositions.current);
      } else {
        imageFigure = getImageFigure(imageData.current);
      }

      setPlotlyImageData(imageFigure.data);
      setPlotlyImageLayout(imageFigure.layout);
    }
  }, [showPeaks]);

  if (!csmIsConnected) {
    return (
      <FlexCol>
        <h1>BLE Page</h1>
        <p>Device not connected</p>
      </FlexCol>
    );
  }

  return (
    <FlexCol>
      <Typography.Title level={2}>BLE Debug Page</Typography.Title>
      <FlexRow>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          Start
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          Stop
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          GetVersions
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetPumpPower,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          GetPump
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetReference,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          SetReference
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetSamplingRate,
                  Payload: new Uint8Array([12]),
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          ChangeFrequency
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            imageData.current = createFilled2dArray(IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_DEFAULT_BLACK);
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetImage,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          GetImage
        </Button>
      </FlexRow>
      <FlexRow
        style={{
          alignItems: 'flex-start',
        }}
      >
        <FlexCol>
          <Typography.Title level={5}>MISO Response:</Typography.Title>
          {misoResponse && <JsonView data={misoResponse} style={defaultStyles} />}
        </FlexCol>
        <FlexCol>
          <Typography.Title level={5}>AVG:</Typography.Title>
          {biosensorsAverageSignal?.toFixed(10)}
        </FlexCol>
        <FlexCol>
          <Typography.Title level={5}>Image:</Typography.Title>
          <Checkbox onChange={onChangePeaks}>Show peaks</Checkbox>
          {plotlyImageData && (
            <Plot
              data={plotlyImageData}
              layout={plotlyImageLayout}
              config={DEFAULT_PLOTLY_CONFIG}
              style={{
                width: `${IMAGE_WIDTH}px`,
                height: `${IMAGE_HEIGHT}px`,
              }}
              useResizeHandler
            />
          )}
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};
