import { FC, useEffect, useState } from 'react';
import { getAllRecords, getFullRecord, RecordKey, RecordValueWithSensograms, updateRecordBoundaries, updateRecordDescription, updateRecordName } from '../../../services/cache/idb';
import { AutoComplete, Col, message, Modal, Row, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FlexCol, FlexRow } from '../../../components/common/common';
import { SingleSensogramFigure } from '../../../components/widgets/Graph/recordSensogram';

export const RecordNameModalDialog: FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  recordKey: RecordKey | null;
}> = ({ isOpen, setIsOpen, recordKey }) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [record, setRecord] = useState<RecordValueWithSensograms | null>(null);

  const [nameOptions, setNameOptions] = useState<string[]>([]);
  const [currentOptions, setCurrentOptions] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllRecords().then((records) => {
      setNameOptions(Array.from(new Set(records.filter((record) => record.name !== undefined).map((record) => record.name as string))));
    });
    setName('');
  }, [recordKey]);

  useEffect(() => {
    if (recordKey === null) {
      return;
    }
    if (!isOpen) {
      setRecord(null);
      return;
    }
    setTimeout(() => {
      if (recordKey === null) {
        return;
      }
      getFullRecord(recordKey).then((record) => {
        console.log('record name modal dialog: record', record);
        setRecord(record);
        setIsLoading(false);
      });
    }, 100);
  }, [recordKey, isOpen]);

  const onOk = async () => {
    if (recordKey === null) {
      console.log('record name modal dialog: record key is null');
      return;
    }
    try {
      await updateRecordName(recordKey, name);
    } catch (e: any) {
      if (e && e.message) {
        message.error(`Could not update record name: ${e.message}`);
      }
      return;
    }
    try {
      await updateRecordDescription(recordKey, description);
    } catch (e: any) {
      if (e && e.message) {
        message.error(`Could not update record description: ${e.message}`);
      }
      return;
    }
    setIsOpen(false);
    message.success(
      <>
        Saved the record under name: <b>{name}</b>!
      </>
    );
  };

  return (
    <Modal title="New Record" open={isOpen} onCancel={() => setIsOpen(false)} onOk={onOk}>
      <FlexCol>
        Name:
        <AutoComplete
          // ref={(input) => input && input.focus()}
          backfill
          tabIndex={0}
          autoFocus
          placeholder="Name"
          options={currentOptions.map((name) => {
            return {
              value: name,
            };
          })}
          onSearch={(value) => {
            let _currentOptions = nameOptions.filter((name) => name.toLowerCase().includes(value.toLowerCase()));
            setCurrentOptions(_currentOptions);
          }}
          value={name}
          onChange={(value) => setName(value)}
        />
        Description:
        <TextArea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
      </FlexCol>
      {record ? (
        <Row gutter={[5, 5]}>
          <Col xs={24}>
            <div
              style={{
                width: '100%',
                height: 300,
              }}
            >
              <SingleSensogramFigure
                record={record}
                shouldAggregate={true}
                setBoundaries={async (startIdx, endIdx) => {
                  record.analyteStart = startIdx;
                  record.analyteEnd = endIdx;
                  void (await updateRecordBoundaries(record.key, undefined, undefined, startIdx, endIdx));
                  // TODO: rerender by setting the existing record, not a newly-fetched one
                  setRecord(record);
                }}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <FlexRow
          style={{
            width: '100%',
            height: 300,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </FlexRow>
      )}
    </Modal>
  );
};
