import { Alert, Row, Col, Typography, message as antdMessage } from 'antd';
import { FC, useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { Link } from 'react-router-dom';
import { classifySignatureWithModel } from '../../analysis/classifier';
import { getSignatureFigure } from '../../analysis/figures';
import { Paper } from '../../common/common';
import { DEFAULT_PLOTLY_LAYOUT, DEFAULT_PLOTLY_CONFIG } from '../../../utils/constants/constants';
import { QuestionningResult } from '../../../types/types';
import { colorTupleToRGBA } from '../../../utils/helpers/utils';
import { ModeledPca } from './modeledPca';
import { loadModel } from '../../../services/cache/localStorage';
import { ColormapWidget } from '../Colormap/colormapWidget';
import { GenericModelV2, ModelCategory } from '../../analysis/definitions';
import BubbleChart, { SecondData } from '../ChemicalFamilyIdentification/BubbleChart';
import { instanceOfGenericModelV2Type } from '../../../utils/helpers/model';

export const QuestonningResultWidget: FC<{
  signature: number[] | null;
  spotsgrid1d: number[] | null;
}> = ({ signature, spotsgrid1d }) => {
  const [signaturePlotlyData, setQuestionningPlotlyData] = useState<Plotly.Data[] | null>(null);
  const [signaturePlotlyLayout, setQuestionningPlotlyLayout] = useState<Partial<Plotly.Layout | null>>(null);
  const [currentModel, setCurrentModel] = useState<GenericModelV2 | null>(null);
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);
  const [currentProba, setCurrentProba] = useState<SecondData[] | null>(null);

  const resultColorRef = useRef<string>('red');

  useEffect(() => {
    let _model = loadModel();
    if (_model === null) return;
    if (!instanceOfGenericModelV2Type(_model)) {
      antdMessage.error(`Old model detected, please migrate it to the new structure !`);
    } else {
      console.log('loaded model', _model);
      setCurrentModel(_model);
    }
  }, []);

  useEffect(() => {
    if (signature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      setQuestionningPlotlyData(null);
      setQuestionningPlotlyLayout(null);
      return;
    }
    if (spotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }

    let [label, point] = classifySignatureWithModel(currentModel, signature, spotsgrid1d);

    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };

    if (currentModel.data.type === ModelCategory.Ellipses) {
      if (Object.keys(currentModel.data.cmap).includes(label)) {
        resultColorRef.current = colorTupleToRGBA(currentModel.data.cmap[label], 1);
      } else {
        resultColorRef.current = 'red';
      }
    } else {
      resultColorRef.current = 'black';
    }

    // handle specific format for checmical predictions models
    if (currentModel.data.type === ModelCategory.ChemicalPrediction) {
      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: Object.entries(currentModel.data.barycenters || {}).map(([key, value]) => ({
            peptide: key,
            ratio: key === label ? 15 : 5, // hardcode found chemical family : size 3 other wise 1.
          })),
        },
      ];
      setCurrentProba(packagedData);
    }

    let { data, layout } = getSignatureFigure(spotsgrid1d, signature, resultColorRef.current);

    layout = {
      ...DEFAULT_PLOTLY_LAYOUT,
      ...layout,
      ...{
        margin: {
          t: 20,
          r: 20,
          b: 20,
          l: 20,
        },
        font: {
          size: 9,
        },
      },
    };

    setQuestionningResult(_questionningResult);
    setQuestionningPlotlyData(data);
    setQuestionningPlotlyLayout(layout);
  }, [signature]);

  if (currentModel === null) {
    return (
      <Paper
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Alert
          type="info"
          style={{
            textAlign: 'center',
            width: '100%',
          }}
          message={
            <>
              Model is not defined.
              <br />
              <br />
              You can train and save the model using previously made records <Link to="/records">here</Link>
            </>
          }
        />
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Row gutter={[5, 10]} justify="center">
        {!questionningResult && (
          <Col>
            <Typography.Title level={4}>Ready to recognize</Typography.Title>
          </Col>
        )}
        {questionningResult && (
          <Col>
            <Typography.Title level={4}>
              Recognized odor:{' '}
              <span
                style={{
                  color: resultColorRef.current,
                }}
              >
                {questionningResult.label}
              </span>
            </Typography.Title>
          </Col>
        )}
      </Row>
      <Row
        gutter={[5, 10]}
        style={{
          width: '100%',
        }}
      >
        {currentModel.data.type === ModelCategory.Ellipses && (
          <Col xs={24}>
            <ColormapWidget cmap={currentModel.data.cmap} />
          </Col>
        )}
        {currentModel.data.type === ModelCategory.Ellipses && (
          <Col
            xs={24}
            lg={signaturePlotlyData && signaturePlotlyLayout ? 12 : 24}
            style={{
              height: 250,
            }}
          >
            <ModeledPca model={currentModel} result={questionningResult} />
          </Col>
        )}
        {currentModel.data.type === ModelCategory.ChemicalPrediction && currentProba !== null && (
          <Col
            xs={24}
            lg={signaturePlotlyData && signaturePlotlyLayout ? 12 : 24}
            style={{
              height: 250,
            }}
          >
            <BubbleChart data={currentProba} currentChemicalFamily={'toto'} height={'250'} />
          </Col>
        )}
        <Col
          xs={24}
          lg={signaturePlotlyData && signaturePlotlyLayout ? 12 : 0}
          style={{
            height: 250,
            display: signaturePlotlyData && signaturePlotlyLayout ? 'block' : 'none',
          }}
        >
          {signaturePlotlyData && signaturePlotlyLayout && (
            <Plot
              data={signaturePlotlyData}
              layout={signaturePlotlyLayout}
              config={DEFAULT_PLOTLY_CONFIG}
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
              }}
              useResizeHandler
            />
          )}
        </Col>
      </Row>
    </Paper>
  );
};
