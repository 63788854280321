import { PushpinOutlined, PushpinTwoTone } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type PinLastRecognitionResultProps = {
  pinLastQuestionningResult: boolean;
  setPinLastQuestionningResult: React.Dispatch<React.SetStateAction<boolean>>;
};

const PinLastRecognitionResult: React.FC<PinLastRecognitionResultProps> = ({ pinLastQuestionningResult, setPinLastQuestionningResult }) => {
  return (
    <Tooltip overlay="Pin last recognition result">
      <Button
        type="text"
        size="large"
        onClick={() => {
          setPinLastQuestionningResult(!pinLastQuestionningResult);
        }}
        icon={pinLastQuestionningResult ? <PushpinTwoTone /> : <PushpinOutlined />}
      />
    </Tooltip>
  );
};

export default PinLastRecognitionResult;
