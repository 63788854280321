import { FC, useEffect, useRef, useState } from 'react';
import { useMessageContext } from '../../state/context/MessageContext';
import { FlexCol, FlexRow, Paper } from '../../components/common/common';

import uPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';
import { Col, Row, Spin } from 'antd';
import { DeviceValue, RecordKey, partitionsTooBig, handleCommitPartitions } from '../../services/cache/idb';
import { DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, PLOT_WINDOW_SIZE } from '../../utils/constants/constants';
import { transpose, mean, standardDeviation, meanPairwiseDifference } from '../../components/analysis/utils';
import { aggregateSignature, sortSignature, normalizeL2, parseBiosensorsSignalMessagePayload, aggregateSeriesFromIndicesMap, getAggregatedSpotsgridIndicesMap, computeEnvelope } from '../../components/analysis/compute';
import { AggregationMethod, Envelope, OdorDetectionInfos } from '../../components/analysis/definitions';
import { processMziData } from '../../components/analysis/mzi';
import { getMziFigureOptions } from '../../components/analysis/figures';
import { initOdorDetectionInfos } from '../../components/analysis/odorstates';
import { QuestonningResultWidget } from '../../components/widgets/Graph/questionningResultWidget';
import { loadSpotsGrid1D } from '../../services/cache/localStorage';
import { navigatorSupportsWebBle } from '../../services/ble/ble';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, decodePumpPower, encodePumpPower } from '../../components/serial/csm';
import { WebBleNotSupportedWidget } from '../../components/widgets/BrowserNotSupported/webBleNotSupportedWidget';
import { v4 as uuidv4 } from 'uuid';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../components/serial/constants';
import SenseTab from './SenseComponents/SenseTab';
import DebugInfo from '../../components/widgets/Parameters/DebugInfos';
import SensorAggregation from './SenseParametersControl/SensorAggregation';
import ResetToZero from '../../components/widgets/Parameters/ResetToZero';
import ClearChart from './SenseParametersControl/ClearChart';
import PinLastRecognitionResult from './SenseParametersControl/PinLastRecognitionResult';
import GlobalParameters from '../../components/widgets/Parameters/GlobalParameters';
import DebugInfoComponenent from './SenseComponents/DebugInfoComponent';
import GlobalParametersComponent, { PumpPowerSettings } from './SenseComponents/GlobalParametersComponent';
import SensingButton from './SenseComponents/SensingButton';
import RecordingButton from './Recording/RecordingButton';
import SpotfileNotFound from '../../components/widgets/Spotfile/SpotfileNotFound';
import RecognitionButton from './Recognition/RecognitionButton';
import { pushSensorDataToRunner } from '../../services/api/runnerApi';
import { pushSliding } from '../../utils/helpers/array';

enum SenseMode {
  Recording,
  Questionning,
}

export const CsmSensePage: FC = () => {
  const { csmMessages, csmIsConnected, csmFwVersion, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});
  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);

  // for acquisition
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));
  const lastDecimationTickRef = useRef<number>(0);
  const rawTimestampSeriesRef = useRef<number[]>([]);
  const rawMZISeriesRef = useRef<number[][]>([]);
  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const decimatedHumiditySeriesRef = useRef<number[]>([]);

  // for correction
  const firstMZIsRef = useRef<number[] | null>(null);

  // for partition storage
  const recordKeyRef = useRef<RecordKey | null>(null);
  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);
  const decimatedHumidityPartitionSeriesRef = useRef<number[]>([]);
  const decimatedTemperaturePartitionSeriesRef = useRef<number[]>([]);

  // for odor detection and recognition
  const signalEnvelopeRef = useRef<Envelope>({ avg: 0, min: 0, max: 0 });
  const odorStateRef = useRef<OdorDetectionInfos>(initOdorDetectionInfos());
  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  // various states
  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);

  // various parameters
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSensing, setIsSensing] = useState<boolean>(true);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [showDebugInfo, setShowDebugInfo] = useState<boolean>(false);
  const [showGlobalParameters, setShowGlobalParameters] = useState<boolean>(false);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const [pinLastQuestionningResult, setPinLastQuestionningResult] = useState<boolean>(true);
  const [senseMode, setSenseMode] = useState<SenseMode>(SenseMode.Recording);
  const [pumpPower, setPumpPower] = useState<PumpPowerSettings>({ setOnDevice: false, value: 45 });
  const [isGraphQlPushRunning, setIsGraphQlPushRunning] = useState(false);

  // plot tooling
  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);
  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const onClickReset = () => {
    firstMZIsRef.current = null;
  };

  const constructSignatureAndRecognize = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC;
    }
    let sectionMZIs = decimatedMZISeriesRef.current.slice(idxStart);
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d, AggregationMethod.Median);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);

    setQuestionningSignature(normalizedSortedAggregatedSignature);
    setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
  };

  const handleOdorPresenceStates = (avgSeries: number[], previousEnvelopeAvg: number) => {
    // ready state --> odor detected state
    if (!odorStateRef.current.isOdorPresent && odorStateRef.current.noizeLevel > 0 && signalEnvelopeRef.current.avg > odorStateRef.current.noizeLevel) {
      odorStateRef.current.thresholdLevel = mean([previousEnvelopeAvg, signalEnvelopeRef.current.avg]);
      odorStateRef.current.startTimestamp = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
      odorStateRef.current.stopTimestamp = 0;
      odorStateRef.current.lastRecognitionTimestamp = Date.now();
      odorStateRef.current.isOdorPresent = true;
    }

    // odor detected state --> ready state
    if (odorStateRef.current.isOdorPresent && signalEnvelopeRef.current.avg < Math.max(odorStateRef.current.maxIntensity * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorStateRef.current.thresholdLevel)) {
      odorStateRef.current.isOdorPresent = false;
      odorStateRef.current.maxIntensity = 0;
      odorStateRef.current.thresholdLevel = 0;
      odorStateRef.current.stopTimestamp = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
      if (!pinLastQuestionningResult) {
        setQuestionningSignature(null);
      } else {
        constructSignatureAndRecognize();
      }
    }

    // ready state
    if (!odorStateRef.current.isOdorPresent) {
      let noizeSeries = avgSeries.slice(-4 * DEFAULT_PLOT_DECIMATED_FPS, -1 * DEFAULT_PLOT_DECIMATED_FPS);
      odorStateRef.current.noizeLevel = mean(noizeSeries) + standardDeviation(noizeSeries) * 12;
    }

    // odor detected state
    if (odorStateRef.current.isOdorPresent) {
      odorStateRef.current.maxIntensity = Math.max(odorStateRef.current.maxIntensity, signalEnvelopeRef.current.avg);

      if (Date.now() - odorStateRef.current.lastRecognitionTimestamp > 250) {
        constructSignatureAndRecognize();
        odorStateRef.current.lastRecognitionTimestamp = Date.now();
      }
    }
  };

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetPumpPower,
      },
    });
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);

    // get mapping for MZI aggregation by peptide to avoid recomputing it on every rendering
    let _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(_spotsgrid1d);
    setAggregatedIndicesMap(_aggregationIndicesMap);
  }, []);

  useEffect(() => {
    // only send updated value if pump power modification comes from the user
    if (pumpPower.setOnDevice) {
      const timeOutId = setTimeout(() => {
        addCSMCommand({
          id: uuidv4().toString(),
          message: {
            CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetPumpPower,
            Payload: encodePumpPower(pumpPower.value),
          },
        });
        console.log('sending setpump command with value', pumpPower);
      }, 500);

      // function for cleaning up previous iteration if this useEffect gets re-executed before the timeout
      return () => clearTimeout(timeOutId);
    } else {
      console.log("avoid setting pump power, wasn't modified by user");
    }
  }, [pumpPower]);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    let t = Date.now();
    // console.log("sense page: acquiring mutex..")
    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        // console.log("sense page: acquired mutex in ", Date.now() - t, "ms")
        let nFramesOnOneMutexLock = 0;
        for (let message of csmMessages) {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);

            if (message.message.Type === CSM_PROTOCOL_COMMAND_TYPE.GetPumpPower) {
              // in that case the response comes from the device Firmware,
              // no need to send again this value to the device firmware
              console.log('received pump power response', message.message);
              let powerValue = decodePumpPower(message.message.Payload);
              setPumpPower({ setOnDevice: false, value: powerValue });
            }
            continue;
          } else {
            nFramesOnOneMutexLock++;
            consumeCSMMessage(message.id);

            // load the spotsgrid
            if (!currentSpotsgrid1d) {
              console.log('sense page: spotsgrid is empty');
              return;
            }

            // ------ Retrieve raw data from the device ------
            // -----------------------------------------------
            let tick = 0;
            let mzis: number[] | null = parseBiosensorsSignalMessagePayload(message, csmFwVersion);
            tick = message.ts;
            if (mzis === null) {
              continue;
            }

            if (isGraphQlPushRunning) {
              pushSensorDataToRunner(mzis, hihValues);
            }

            rawMZISeriesRef.current.push(mzis);
            rawTimestampSeriesRef.current.push(tick);

            // ------ control decimation and compute FPS ------
            // ------------------------------------------------
            if (tick - lastDecimationTickRef.current < 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
              continue;
            }

            let previousTick = lastDecimationTickRef.current;
            lastDecimationTickRef.current = tick;
            pushSliding(decimatedTimestampSeriesRef.current, tick, PLOT_WINDOW_SIZE);

            let rawTimestampInterval = meanPairwiseDifference([previousTick, ...rawTimestampSeriesRef.current]);
            let rawFps = 1000 / rawTimestampInterval;
            setRawFps(rawFps);
            rawTimestampSeriesRef.current = [];

            let decimatedTimestampInterval = meanPairwiseDifference(decimatedTimestampSeriesRef.current);
            let decimatedFps = 1000 / decimatedTimestampInterval;
            setDecimatedFps(decimatedFps);

            // ------ Process and get last decimated MZI frame ------
            // ------------------------------------------------------
            // Humidity correction not applied here because it has to be applied on all decimatedMZI buffer not juste on one frame
            let MziProcessingResult = processMziData(rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, false, undefined, null, hihValues);
            rawMZISeriesRef.current = [];

            if (MziProcessingResult === undefined) continue;
            let [, uncorrectedDecimatedMzis, decimatedMzis] = MziProcessingResult;

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...uncorrectedDecimatedMzis];
              decimatedMzis.forEach((mzi, index) => {
                decimatedMzis[index] -= uncorrectedDecimatedMzis[index];
              });
            }

            pushSliding(decimatedMZISeriesRef.current, decimatedMzis, PLOT_WINDOW_SIZE);
            pushSliding(decimatedHumiditySeriesRef.current, hihValues.humidity, PLOT_WINDOW_SIZE);

            // ------ Store unprocessed decimated partitions  ------
            // -----------------------------------------------------
            // no need to fill all those series if we are not recording !
            if (isRecording && recordKeyRef.current !== null) {
              decimatedTimestampPartitionSeriesRef.current.push(tick);
              decimatedMZIPartitionSeriesRef.current.push(decimatedMzis); // save non-aggreated mzis regardless of shouldAggregate
              decimatedHumidityPartitionSeriesRef.current.push(hihValues.humidity);
              decimatedTemperaturePartitionSeriesRef.current.push(hihValues.temperature);

              if (partitionsTooBig(decimatedMZIPartitionSeriesRef.current)) {
                handleCommitPartitions(recordKeyRef.current, decimatedTimestampPartitionSeriesRef.current, decimatedMZIPartitionSeriesRef.current, decimatedHumidityPartitionSeriesRef.current, decimatedTemperaturePartitionSeriesRef.current);
                decimatedMZIPartitionSeriesRef.current = [];
                decimatedTimestampPartitionSeriesRef.current = [];
                decimatedHumidityPartitionSeriesRef.current = [];
                decimatedTemperaturePartitionSeriesRef.current = [];
              }
            }

            // ------ Final processing ------
            // ------------------------------
            // MZI processing applied on whole buffer
            // aggregate mzis timeseries (frame by frame) if needed
            let finalMZIsSeries: number[][] = [];
            let seriesLabels: number[] = [];
            if (shouldAggregate) {
              [finalMZIsSeries, seriesLabels] = aggregateSeriesFromIndicesMap(decimatedMZISeriesRef.current, aggregatedIndicesMap, AggregationMethod.Median);
            } else {
              finalMZIsSeries = decimatedMZISeriesRef.current;
              seriesLabels = [...currentSpotsgrid1d];
            }

            // Note : you can add any further processing on whole MZI series here !
            // Just keep in mind the input for questioning signature computation is NOT the finalMZIsSeries, but the decimatedMZIsSeries
            // Indeed, the questioning signature has its own processing pipeline, in constructSignatureAndRecognize

            let nDims = seriesLabels.length;
            if (finalMZIsSeries.length < 2) {
              continue;
            }

            let lastFrame: number[] = finalMZIsSeries[finalMZIsSeries.length - 1];
            [signalEnvelopeRef.current.avg, signalEnvelopeRef.current.min, signalEnvelopeRef.current.max] = computeEnvelope(lastFrame, seriesLabels);
            let [previousEnvelopeAvg, ,] = computeEnvelope(finalMZIsSeries[finalMZIsSeries.length - 2], seriesLabels);

            let avgSeries: number[] = [];
            for (let i = 0; i < finalMZIsSeries.length; i++) {
              let [frameAvg, ,] = computeEnvelope(finalMZIsSeries[i], seriesLabels);
              avgSeries.push(frameAvg);
            }

            // State handling (smelling something or not)
            handleOdorPresenceStates(avgSeries, previousEnvelopeAvg);

            // ------ Build plots ------
            // -------------------------
            if (mziUplotOptions === null) {
              let opts = getMziFigureOptions(seriesLabels, shouldAggregate, signalEnvelopeRef.current, odorStateRef.current, mziTooltipRef.current);
              setMziUplotOptions(opts);
            }

            let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000);
            let Ys = [];
            for (let i = 0; i < nDims; i++) {
              let Y = [];
              for (let j = 0; j < finalMZIsSeries.length; j++) {
                Y[j] = finalMZIsSeries[j][i];
              }
              Ys.push(Y);
            }
            Ys.push(avgSeries);
            Ys.push(decimatedHumiditySeriesRef.current);

            let data: uPlot.AlignedData = [X, ...Ys];
            setMziUplotData(data);
            continue;
          }
        }
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    if (mziUplotOptions !== null && mziUplotData !== null && mziTargetRef.current !== null) {
      if (mziUplotRef.current === null || shouldRedraw) {
        if (mziUplotRef.current !== null) {
          mziUplotRef.current.destroy();
          mziUplotRef.current = null;
        }
        let plot = new uPlot(mziUplotOptions, mziUplotData, mziTargetRef.current);
        plot.setSize({
          width: mziTargetRef.current.clientWidth,
          height: mziTargetRef.current.clientHeight,
        });
        mziUplotRef.current = plot;
        if (shouldRedraw) {
          setShouldRedraw(false);
        }
      } else {
        mziUplotRef.current.setData(mziUplotData);
        mziUplotRef.current.setSize({
          width: mziTargetRef.current.clientWidth,
          height: mziTargetRef.current.clientHeight,
        });
      }
    } else {
      console.log('sense page: could not create mzi uplot', mziUplotOptions, mziUplotData, mziTargetRef.current);
    }
  }, [mziUplotOptions, mziUplotData, mziTargetRef.current]);

  useEffect(() => {
    const cleanup = () => {
      console.info('sense page: destroying uplot refs');
      if (mziUplotRef.current !== null) {
        mziUplotRef.current.destroy();
        mziUplotRef.current = null;
      }
      setMziUplotOptions(null);
      setMziUplotData([]);
    };
    cleanup();
    return cleanup;
  }, []);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  if (!navigatorSupportsWebBle()) {
    return <WebBleNotSupportedWidget />;
  }

  if (!csmIsConnected) {
    console.log('sense page: refkit is not connected. Redirecting to connect page');
    return null;
  }
  if (isLoading) {
    return (
      <FlexCol style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spin size="large" />
      </FlexCol>
    );
  }

  if (currentSpotsgrid1d === null) {
    return <SpotfileNotFound />;
  }

  return (
    <FlexCol style={{ justifyContent: 'center', alignItems: 'start', width: '100%' }}>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          <SenseTab isRecording={isRecording} senseMode={senseMode} setSenseMode={setSenseMode} />

          <FlexRow style={{ width: '100%', justifyContent: 'end', alignItems: 'center', gap: 5 }}>
            <div ref={mziTooltipRef}></div>
            <DebugInfo showDebugInfo={showDebugInfo} setShowDebugInfo={setShowDebugInfo} />
            <GlobalParameters showGlobalParameters={showGlobalParameters} setShowGlobalParameters={setShowGlobalParameters} />
            <SensorAggregation shouldAggregate={shouldAggregate} setMziUplotOptions={setMziUplotOptions} setMziUplotData={setMziUplotData} setShouldAggregate={setShouldAggregate} setShouldRedraw={setShouldRedraw} />
            <ResetToZero firstMZIsRef={firstMZIsRef} onClickReset={onClickReset} />
            <ClearChart decimatedMZISeriesRef={decimatedMZISeriesRef} decimatedTimestampSeriesRef={decimatedTimestampSeriesRef} displayedHumiditySeriesRef={decimatedHumiditySeriesRef} />
            <PinLastRecognitionResult pinLastQuestionningResult={pinLastQuestionningResult} setPinLastQuestionningResult={setPinLastQuestionningResult} />
          </FlexRow>

          {/* MZI Chart */}
          <Row
            gutter={[10, 10]}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Col xs={24} lg={senseMode === SenseMode.Recording ? 24 : 12}>
              <FlexRow
                style={{
                  width: '100%',
                  height: '100%',
                  padding: isRecording ? 3 : 0,
                  borderRadius: 10,
                  backgroundColor: isRecording ? 'red' : 'transparent',
                }}
              >
                <Paper
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 300,
                      maxHeight: '70vh',
                    }}
                    ref={mziTargetRef}
                  />
                </Paper>
              </FlexRow>
            </Col>
            <Col xs={24} lg={12}>
              {senseMode === SenseMode.Questionning && (
                <FlexRow
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <QuestonningResultWidget signature={questionningSignature} spotsgrid1d={questionningSpotsgrid1d} />
                </FlexRow>
              )}
            </Col>
          </Row>
          {/* Recording controls*/}
          <Row gutter={[5, 5]} style={{ width: '100%' }}>
            <Col xs={24} md={12}>
              <SensingButton isSensing={isSensing} setIsSensing={setIsSensing} />
            </Col>
            <Col xs={24} md={12}>
              <RecordingButton
                senseMode={senseMode}
                recordKeyRef={recordKeyRef}
                decimatedMZIPartitionSeriesRef={decimatedMZIPartitionSeriesRef}
                decimatedTimestampPartitionSeriesRef={decimatedTimestampPartitionSeriesRef}
                decimatedHumidityPartitionSeriesRef={decimatedHumidityPartitionSeriesRef}
                decimatedTemperatureSeriesRef={decimatedTemperaturePartitionSeriesRef}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                deviceValue={deviceValue}
              />
              <RecognitionButton senseMode={senseMode} constructSignatureAndRecognize={constructSignatureAndRecognize} />
            </Col>
          </Row>

          <GlobalParametersComponent showGlobalParameters={showGlobalParameters} pumpPower={pumpPower} setPumpPower={setPumpPower} isGraphQlPushRunning={isGraphQlPushRunning} setIsGraphQlPushRunning={setIsGraphQlPushRunning} />
          <DebugInfoComponenent showDebugInfo={showDebugInfo} rawFps={rawFps} decimatedFps={decimatedFps} hihValues={hihValues} />
        </>
      )}
    </FlexCol>
  );
};
