export const SELECTED_RECORDS_STORAGE_KEY = 'selectedRecordKeys';
export const RECORDS_TABLE_PAGE_SIZE_KEY = 'recordsTablePageSize';

/**
 * Charge la liste des RecordKey sélectionnés depuis localStorage.
 */
export function loadSelectedRecordKeys(): string[] {
  try {
    const stored = window.localStorage.getItem(SELECTED_RECORDS_STORAGE_KEY);
    if (!stored) return [];
    return JSON.parse(stored);
  } catch (e) {
    console.error('Failed to parse stored selected record keys:', e);
    return [];
  }
}

export function saveSelectedRecordKeys(keys: string[]): void {
  window.localStorage.setItem(SELECTED_RECORDS_STORAGE_KEY, JSON.stringify(keys));
}

export function loadRecordsTablePageSize(): number {
  try {
    const stored = window.localStorage.getItem(RECORDS_TABLE_PAGE_SIZE_KEY);
    if (!stored) return 10;
    return JSON.parse(stored);
  } catch (e) {
    console.error('Failed to parse table page size:', e);
    return 10;
  }
}

export function saveRecordsTablePageSize(size: number): void {
  window.localStorage.setItem(RECORDS_TABLE_PAGE_SIZE_KEY, JSON.stringify(size));
}
