import { message as antdMessage } from 'antd';
import { Model } from './definitions';
import { putModel } from '../../services/cache/idb';
import { isProductionEnv } from '../../utils/helpers/utils';

const MODEL_ENDPOINT = 'https://gitlab.com/api/v4/projects/Aryballe%2Falgorithms%2Fmodel-library/repository';
const MODEL_TOKEN = 'glpat-xxm-gFbsq5yad24xQYNg';

const getModelSubfolder = (): string => {
  return isProductionEnv() ? 'production' : 'staging';
};

export const fetchModelList = async (): Promise<string[]> => {
  let page = 1;
  let modelFileList: string[] = [];
  try {
    while (true) {
      const _modelFileList = await _fetchModelListPage(page);
      if (_modelFileList.length === 0) {
        break;
      }
      modelFileList = modelFileList.concat(_modelFileList);
      page++;
    }
  } catch (error) {
    antdMessage.error(`Error fetching online models: ${error}`);
  }
  return modelFileList;
};

const _fetchModelListPage = async (page: number): Promise<string[]> => {
  const modelSubfolder = getModelSubfolder();
  const response = await fetch(`${MODEL_ENDPOINT}/tree?path=model_data%2F${modelSubfolder}&access_token=${MODEL_TOKEN}&page=${page}&per_page=100`);
  let modelFileList: any[] = await response.json();

  console.log(modelFileList);
  modelFileList = modelFileList.filter((x) => x.type === 'blob');
  console.log(modelFileList);
  return modelFileList.map((file) => file.name.replace('.json', ''), ''); // trim extension
};

export const fetchModel = async (modelName: string) => {
  try {
    const modelSubfolder = getModelSubfolder();
    const response = await fetch(`${MODEL_ENDPOINT}/files/model_data%2F${modelSubfolder}%2F${modelName}.json/raw?access_token=${MODEL_TOKEN}`);
    var model: Model = await response.json();
    if (model.metadata.ID === undefined) {
      model.metadata.ID = modelName;
    }
    model.metadata.spotfile = model.metadata.spotfile.map((x) => Number(x)); // for retrocompatibility cast eventual string[] spotfiles to the right type
    await putModel(model);
    antdMessage.success(`Successfully fetched model ${modelName} from the cloud`);
  } catch (error) {
    antdMessage.error(`Error fetching model ${modelName} from the cloud: ${error}`);
  }
};
