import { ForwardOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

type ClearChartProps = {
  decimatedMZISeriesRef: React.MutableRefObject<number[][]>;
  decimatedTimestampSeriesRef: React.MutableRefObject<number[]>;
  displayedHumiditySeriesRef: React.MutableRefObject<number[]>;
};

const ClearChart: React.FC<ClearChartProps> = ({ decimatedMZISeriesRef, decimatedTimestampSeriesRef, displayedHumiditySeriesRef }) => {
  return (
    <Tooltip overlay="Clear chart">
      <Button
        type="text"
        size="large"
        onClick={() => {
          decimatedMZISeriesRef.current = [];
          decimatedTimestampSeriesRef.current = [];
          displayedHumiditySeriesRef.current = [];
        }}
        icon={<ForwardOutlined />}
      />
    </Tooltip>
  );
};

export default ClearChart;
